
import { useState, useEffect } from 'react';
import {
    Box,
    makeStyles, Select, MenuItem,
    TextField, InputAdornment, FormControl, Checkbox,
    Tooltip
} from '@material-ui/core';
//Helpers.
import { blueBar } from '../../../helpers/common'
import { MAVG_OPTIONS, INA_OPTIONS, BOOLEAN_TEXT_OPTIONS, BOOL_OPTIONS } from './helperDescriptionText';
import { getSettingBlock, spanishLocaleForCron } from '../helpers';
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import HelpIcon from '@mui/icons-material/Help';
import { MarkdownRenderer } from './helperDescriptionText';


const useStyles = makeStyles(theme => ({
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.primary.dark,
    },
    content: {
        maxWidth: '100%',            // Asegura que el contenido no se expanda más allá del ancho disponible
        fontSize: 14,                // Tamaño de fuente
        color: '#5c5d5d',            // Color del texto
        wordWrap: 'break-word',      // Rompe las palabras largas para ajustarlas al contenedor
        overflowWrap: 'break-word',  // Rompe las palabras largas en el contenido
        whiteSpace: 'normal',        // Permite que el texto se apile en lugar de desbordar
        overflowX: 'auto',           // Habilita el scroll horizontal si el contenido excede el ancho
        overflowY: 'hidden',         // Deshabilita el scroll vertical si no lo necesitas
        // Espaciado inferior    
        // Personalización de la barra de desplazamiento
        '&::-webkit-scrollbar': {
            height: '9px',
            background: 'white',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#beb1b18f',
        },
        '&::-webkit-scrollbar-track': {
            background: '#dfdfe957',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },   //
    },


    "@global": {
        ".react-js-cron-select-dropdown": {
            zIndex: 1500,
        },
        ".ant-select-multiple .ant-select-selection-overflow": {
            flex: 'none'
        }
    },

}));

const GenericFilterOptions = ({ varState, setVarState, blockNodeSettings,
    typeBlock, id, stateDiagram, userConfiguration, nodes, setDisabledButtonSave, disableEditing }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    const [stateForm, setStateForm] = useState({})
    const [errorLabel, setErrorLabel] = useState('')
    const [helpLabel, setHelpLabel] = useState('')
    const [pinSelected, setPinesSelected] = useState([])

    useEffect(() => {
        mergeData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let booleanBlocks = ['INA', 'IND', 'OUTD', 'OUTA']
        let copiyNodes = JSON.parse(JSON.stringify(nodes))
        let filterElement = copiyNodes.filter(elemento => booleanBlocks.includes(elemento.type));
        let ArryAux = []
        for (let i = 0; i < filterElement.length; i++) {
            const element = filterElement[i]
            if (element?.a?.i) {
                if (element.id === id) {
                    ArryAux.push(stateForm?.a?.i)
                }
                else {
                    ArryAux.push(element?.a?.i)
                }
            }
            if (element?.a?.o) {
                if (element.id === id) {
                    ArryAux.push(stateForm?.a?.o)
                }
                else {
                    ArryAux.push(element?.a?.o)
                }

            }
        }
        setPinesSelected(ArryAux)
        // eslint-disable-next-line
    }, [nodes, stateForm])

    const convertSecondsToCentiseconds = (objeto) => {
        // Hacer una copia profunda del objeto para no modificar el original
        let copia = JSON.parse(JSON.stringify(objeto));
        // Verifica si el objeto tiene la propiedad 'a'
        if (copia.hasOwnProperty('a')) {
            // Recorre todas las propiedades de 'a'
            for (let key in copia.a) {
                // Verifica si la propiedad es del objeto y no del prototipo
                if (copia.a.hasOwnProperty(key)) {
                    //Si es la propiedad c de el bloque TCRON no formatees.
                    if (key === 'c' && typeBlock === 'TCRON') {
                    }
                    else if ((key === 'id' || key === 'n' || key === 'u') && typeBlock === 'VAUP') {
                    }
                    else {
                        // Divide el valor de la propiedad por 100 para convertir a segundos
                        copia.a[key] /= 100;
                    }
                }
            }
        }
        return copia;
    }

    const mergeData = () => {
        let copy = Object.assign({}, varState);
        //convertir centisegundos a segundos si es symbol 'Segundos'.
        if (blockSecondTime()) {
            let res = convertSecondsToCentiseconds(copy)
            setStateForm(res)
        }
        else if (typeBlock === 'VAUP') {
            let res = convertSecondsToCentiseconds(copy)
            setStateForm(res)
        }

        else {
            if (typeBlock === 'COUNT') {
                let auxObje = copy
                if (copy?.a?.o === undefined) {
                    auxObje = {
                        ...copy,
                        a: {
                            ...copy.a,
                            o: true
                        }
                    }
                }
                setStateForm(auxObje)
            }
            else {
                setStateForm(copy)
            }
        }
    }

    //Crea un array dinamico para opciones.
    const crearArrayValores = (inicio, fin, letra) => {
        let valuesArray = [];
        for (let i = 0; i <= fin - inicio; i++) {
            valuesArray.push({
                name: letra + (i + 1).toString(),
                key: (inicio + i).toString()
            });
        }
        return valuesArray;
    }

    //Valida q el valor sea de 0 a 0.99
    const validateLPF = (number) => {
        // Convert the input to a floating-point value
        const num = parseFloat(number);
        // Return true if the number is numeric and within the range [0, 0.99)
        return !isNaN(num) && num >= 0 && num <= 0.99;
    }

    //Funcion q valida si el valor es flotante
    function isFloat(value) {
        // Intenta convertir el valor a un número
        let number = parseFloat(value);
        // Verifica si el resultado de la conversión es NaN
        if (isNaN(number)) {
            return false;
        }
        // Verifica si el número tiene una parte decimal
        return number % 1 !== 0;
    }

    const checkValueFormat = (value, name) => {
        //LPF VALIDATE
        if (typeBlock === 'LPF') {
            if (validateLPF(value)) {
                setDisabledButtonSave(false)
                setErrorLabel('')
            }
            else {
                setDisabledButtonSave(true)
                setErrorLabel('El valor debe ser desde 0 a 0.99')
            }
            return value?.toString()
        }
        //RAND VALIDATE
        if (typeBlock === 'RAND') {
            if (stateForm?.a?.t === '0') {
                setHelpLabel('')
            }
            if (stateForm?.a?.t === '1') {
                setHelpLabel('Los valores ingresado debe ser un número entero.')
            }
            if (stateForm?.a?.t === '2') {
                setHelpLabel('Los valores ingresado debe ser un número no signado.')
            }
            if (stateForm?.a?.t === '3') {
                if (!isFloat(value)) {
                    setHelpLabel('Los valores ingresado debe ser un número flotante, ej: 0.99.')
                }
            }
            setDisabledButtonSave(false)
            return value?.toString()
        }

        if (blockSecondTime()) {
            if (value < 0) {
                setDisabledButtonSave(true)
                setErrorLabel('El valor debe ser mayor a cero')
            }
            else {
                setDisabledButtonSave(false)
                setErrorLabel('')
            }
            return value?.toString()
        }

        if (value) {
            setDisabledButtonSave(false)
        }
        return value?.toString()
    }

    // Convierte a entero sin signo de 32 bits
    function transformUnit(value) {
        let resultado = value * 100;
        resultado = Math.round(resultado);
        return resultado.toString()
    }

    //Funcion q valida q no se repita ningun pin en los bloques I/O
    let booleanBlocks = ['INA', 'IND', 'OUTD', 'OUTA']
    const lookinForPinEqual = (value, name) => {
        setErrorLabel('')
        if (booleanBlocks.includes(typeBlock)) {
            let copiyNodes = JSON.parse(JSON.stringify(nodes))
            let filterElement = copiyNodes.filter(elemento => booleanBlocks.includes(elemento.type));
            for (let i = 0; i < filterElement.length; i++) {
                const element = filterElement[i]
                if (name !== 'm') {

                    if (element.id === id) {
                        if (value === stateForm?.a?.o || (value === stateForm?.a?.i)) {
                            setErrorLabel('Ya tiene este pin seleccionado.')
                            return false
                        }
                    }
                    else {
                        if (value === element?.a?.o || (value === element?.a?.i)) {
                            setErrorLabel('Ya tiene este pin seleccionado.')
                            return false
                        }
                    }
                }
            }
            return true
        }
        else {
            return true
        }
    }

    //Bloques q usan segundos y formatean su valor
    const blockSecondTime = () => {
        let blocksTime = ['PULSEGEN', 'THOLD', 'TPUL', 'PWM', 'TRET', 'TOFF', 'TON', 'AVG', 'TCRON']
        return blocksTime.includes(typeBlock)
    }


    const handleChange = (e) => {
        let value = e.target.value
        let name = e.target.name
        const { checked } = e.target
        if (lookinForPinEqual(value, name)) {
            setStateForm({
                ...stateForm,
                a: {
                    ...stateForm.a,
                    [name]: checkValueFormat(value, name)
                }
            })

            if (typeBlock === 'COUNT') {
                let objAux = {
                    ...stateForm,
                    a: {
                        c: value,
                        o: true
                    }
                }
                if (name === 'c') {
                    objAux = {
                        ...varState,
                        a: {
                            ...stateForm.a,
                            c: value,
                        }
                    }
                }
                if (name === 'o') {
                    objAux = {
                        ...stateForm,
                        a: {
                            ...stateForm.a,
                            o: checked,
                        }
                    }
                }
                setVarState(objAux)
                setStateForm(objAux)
                return
            }

            if (typeBlock === 'RAND') {
                if (name === 't' && value === '0') {
                    setVarState({
                        ...varState,
                        a: {
                            t: '0',
                            min: false,
                            max: true
                        }
                    })
                    setStateForm({
                        ...varState,
                        a: {
                            t: '0',
                            min: false,
                            max: true
                        }
                    })
                    return
                }
            }


            if (typeBlock === 'CONST') {
                if (stateDiagram?.componentType === 'CREATOR') {
                    setVarState({
                        ...varState,
                        a: {
                            ...varState.a,
                            [name]: value
                        }
                    })
                }
                else {
                    setVarState({
                        ...varState,
                        values: value
                    })
                }

            }
            else if (typeBlock === 'TCRON') {
                setVarState({
                    ...varState,
                    a: {
                        ...varState.a,
                        [name]: name === 't' ? transformUnit(value) : checkValueFormat(value, name)
                    },
                })
            }
            else if (typeBlock === 'VAUP') {
                setVarState({
                    ...varState,
                    a: {
                        ...varState.a,
                        [name]: name === 'p' ? transformUnit(value) : checkValueFormat(value, name)
                    },
                })
            }
            else {
                setVarState({
                    ...varState,
                    a: {
                        ...varState.a,
                        [name]: blockSecondTime() ? transformUnit(value) : checkValueFormat(value, name)
                    },
                })

            }
        }
    }

    const disabledPin = (value) => {
        let valueAux = value
        pinSelected.includes()
        let res = pinSelected.includes(valueAux)
        return res
    }

    //Devuelve el tipo de valor q es en el input segun la propiedad.
    const returnTypeInput = (name) => {
        if (name === 'Nombre' || name === 'Unidad') {
            return 'text'
        }
        return 'number'
    }

    return (
        <Box>
            {/* TITULO */}
            <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>{userConfiguration ? `${getSettingBlock(typeBlock)?.name}` : `${getSettingBlock(typeBlock)?.name}`}</h3>
            </Box >

            {blueLine}

            <Box mb={2} >
                <Box className={classes.content} ml={1}>
                    <MarkdownRenderer markdown={blockNodeSettings?.description ? blockNodeSettings?.description : ''} />
                </Box>
            </Box>



            {blueLine}

            <Box ml={1} mt={2} >
                {typeBlock === 'CONST' ?
                    <Box>
                        {stateDiagram?.componentType === 'CREATOR' || stateDiagram?.componentType === 'Automate-24V-4M' ?
                            <Box>
                                <Box mt={2}>
                                    {/* STEP 1 */}
                                    <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                        <Box className={classes.devicesLabel}>
                                            Elija un tipo de dato
                                        </Box>
                                    </Box>
                                    <Box width={'80%'}>
                                        <FormControl fullWidth>
                                            <Select
                                                disabled={disableEditing}
                                                name='t'
                                                value={varState?.a?.t ? varState?.a?.t : ''}
                                                onChange={e => handleChange(e)}
                                            >
                                                {BOOLEAN_TEXT_OPTIONS.map((elem, indx) => (
                                                    <MenuItem
                                                        key={indx}
                                                        value={elem.key}
                                                    >
                                                        {elem.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                {/* STEP 2 */}
                                <Box mt={2} width={'80%'}>
                                    <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                    </Box>
                                    {varState?.a?.t?.toString() === '0' ?
                                        <Box >
                                            <FormControl fullWidth>
                                                <Select
                                                    disabled={disableEditing}
                                                    name='v'
                                                    value={varState?.a?.v !== undefined ? varState?.a?.v : ''}
                                                    onChange={e => handleChange(e)}
                                                >
                                                    {BOOL_OPTIONS.map((elem, indx) => (
                                                        <MenuItem
                                                            key={indx}
                                                            value={elem.key}
                                                        >
                                                            {elem.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        :
                                        <Box width={'100%'}>
                                            <Box >
                                                <Box className={classes.input}>
                                                    <TextField
                                                        disabled={disableEditing}
                                                        fullWidth
                                                        type={"number"}
                                                        name={'v'}
                                                        label={'Agregue un valor'}
                                                        size='small'
                                                        variant='outlined'
                                                        value={varState?.a?.v ? varState?.a?.v : ''}

                                                        onChange={e => handleChange(e)}
                                                        color='primary'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            :
                            <Box>
                                {/* STEP 1 */}
                                <Box>
                                    <Box>
                                        <Box width={'80%'}>
                                            <Box className={classes.input} >
                                                <TextField
                                                    disabled={disableEditing}
                                                    fullWidth
                                                    type={"number"}
                                                    name={'v'}
                                                    label={'Agregue un valor'}
                                                    size='small'
                                                    variant='outlined'
                                                    value={varState?.values ? varState?.values : ''}
                                                    onChange={e => handleChange(e)}
                                                    color='primary'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                    :

                    typeBlock === 'RAND' ?
                        <>
                            {
                                (Array.isArray(blockNodeSettings?.atributes_keys) ? blockNodeSettings?.atributes_keys : []).map((element) => {
                                    return (
                                        <Box>
                                            {/* STEP 1 */}
                                            {
                                                element?.key === 't' ?
                                                    <Box>
                                                        <Box className={classes.devicesLabel}>
                                                            {element?.name}
                                                        </Box>
                                                        <Box width={'80%'} className={classes.input} >
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    disabled={disableEditing}
                                                                    name={element.key}
                                                                    value={varState?.a?.t !== undefined ? varState?.a?.t : ''}
                                                                    onChange={e => handleChange(e)}
                                                                >
                                                                    {BOOLEAN_TEXT_OPTIONS.map((elem, indx) => (
                                                                        <MenuItem
                                                                            key={indx}
                                                                            value={elem.key}
                                                                        >
                                                                            {elem.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Box width={'80%'} mt={2} className={classes.input}>
                                                        <TextField
                                                            fullWidth
                                                            type={stateForm?.a?.t === '0' ? '' : "number"}
                                                            disabled={disableEditing ? disableEditing : (stateForm?.a?.t === '0' ? true : false)}
                                                            name={element.key}
                                                            label={element.name}
                                                            size='small'
                                                            variant='outlined'
                                                            value={stateForm?.a?.t === '0' ? (element.key === 'min' ? false : true) : (stateForm?.a?.[element.key] ? stateForm?.a?.[element.key] : '')}
                                                            onChange={e => handleChange(e)}
                                                            color='primary'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position='end'>{blockSecondTime() ? 'Segundos' : ''}</InputAdornment>,
                                                            }}
                                                        />
                                                    </Box>
                                            }
                                        </Box>
                                    )
                                })

                            }
                        </>
                        :
                        typeBlock === 'MAVG' ?
                            <Box className={classes.input}>
                                <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                    <Box className={classes.devicesLabel}>
                                        {blockNodeSettings?.atributes_keys[0]?.name}
                                    </Box>
                                </Box>
                                <Box width={'80%'}>
                                    <FormControl fullWidth>
                                        <Select
                                            disabled={disableEditing}
                                            name={blockNodeSettings?.atributes_keys[0]?.key}
                                            value={stateForm?.a?.n ? stateForm?.a?.n : ''}
                                            onChange={e => handleChange(e)}
                                        >
                                            {MAVG_OPTIONS.map((elem, indx) => (
                                                <MenuItem
                                                    key={indx}
                                                    value={elem.key}
                                                >
                                                    {elem.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            :
                            typeBlock === 'TCRON' ?
                                <Box>
                                    {/* STEP 1 */}
                                    <Box width={'90%'} display="flex" alignItems="center" flexWrap="wrap">
                                        <Cron
                                            value={stateForm?.a?.['c'] ? stateForm?.a?.['c'] : ''}
                                            clearButtonProps={{
                                                style: {
                                                    background: '#2586bc'
                                                }
                                            }}
                                            setValue={(e) => {
                                                setStateForm({
                                                    ...stateForm,
                                                    a: {
                                                        ...stateForm.a,
                                                        c: e
                                                    }
                                                })
                                                setVarState({
                                                    ...varState,
                                                    a: {
                                                        ...varState.a,
                                                        c: e
                                                    },
                                                })
                                            }}
                                            locale={spanishLocaleForCron}
                                        />
                                    </Box>
                                    <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Box mt={2} width={'90%'}>
                                            <TextField
                                                fullWidth
                                                type={"text"}
                                                name={blockNodeSettings?.atributes_keys[0].key}
                                                label={blockNodeSettings?.atributes_keys[0].name}
                                                size='small'
                                                variant='outlined'
                                                value={stateForm?.a?.['c'] ? stateForm?.a?.['c'] : ''}
                                                onChange={e => handleChange(e)}
                                                color='primary'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                        <Box mt={2} ml={1}>
                                            <Tooltip title={'Puede completar la expresión CRON ingresándola directamente en el campo designado o mediante el formulario que se encuentra en la parte superior.'}>
                                                <HelpIcon style={{ fontSize: '22px', color: '#2586bc' }} />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                    {/* STEP 2 */}
                                    <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                    </Box>
                                    <Box mt={2} width={'90%'} className={classes.input} >
                                        <TextField
                                            disabled={disableEditing}
                                            fullWidth
                                            type={"number"}
                                            name={blockNodeSettings?.atributes_keys[1].key}
                                            label={blockNodeSettings?.atributes_keys[1].name}
                                            size='small'
                                            variant='outlined'
                                            value={stateForm?.a?.['t'] ? stateForm?.a?.['t'] : ''}
                                            onChange={e => handleChange(e)}
                                            color='primary'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>{'Segundos'}</InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                </Box>
                                :
                                typeBlock === 'CAST' ?
                                    <Box>
                                        <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                            <Box className={classes.devicesLabel}>
                                                {blockNodeSettings?.atributes_keys[0]?.name}
                                            </Box>
                                        </Box>
                                        <Box width={'80%'}>
                                            <FormControl fullWidth>
                                                <Select
                                                    disabled={disableEditing}
                                                    name={blockNodeSettings?.atributes_keys[0]?.key}
                                                    value={stateForm?.a?.t ? stateForm?.a?.t : ''}
                                                    onChange={e => handleChange(e)}
                                                >
                                                    {BOOLEAN_TEXT_OPTIONS.map((elem, indx) => (
                                                        <MenuItem
                                                            key={indx}
                                                            value={elem.key}
                                                        >
                                                            {elem.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    :

                                    typeBlock === 'INA' || typeBlock === 'IND' ?
                                        <Box>
                                            <Box display="flex" alignItems="center" flexWrap="wrap" >
                                                <Box className={classes.devicesLabel}>
                                                    {blockNodeSettings?.atributes_keys[0]?.name}
                                                </Box>
                                            </Box>
                                            <Box width={'80%'}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        disabled={disableEditing}
                                                        name={blockNodeSettings?.atributes_keys[0]?.key}
                                                        value={stateForm?.a?.i ? stateForm?.a?.i : ''}
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        {crearArrayValores(0, 11, 'P').map((elem, indx) => (
                                                            <MenuItem
                                                                key={indx}
                                                                value={elem.key}
                                                                disabled={disabledPin(elem.key)}
                                                            >
                                                                {elem.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            {typeBlock === 'INA' &&
                                                <Box>
                                                    <Box mt={2} display="flex" alignItems="center" flexWrap="wrap"  >
                                                        <Box className={classes.devicesLabel}>
                                                            {blockNodeSettings?.atributes_keys[1]?.name}
                                                        </Box>
                                                    </Box>
                                                    <Box width={'80%'}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                disabled={disableEditing}
                                                                name={blockNodeSettings?.atributes_keys[1]?.key}
                                                                value={stateForm?.a?.m ? stateForm?.a?.m : ''}
                                                                onChange={e => handleChange(e)}
                                                            >
                                                                {INA_OPTIONS.map((elem, indx) => (
                                                                    <MenuItem
                                                                        key={indx}
                                                                        value={elem.key}
                                                                    >
                                                                        {elem.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Box>}
                                        </Box>
                                        :
                                        typeBlock === 'OUTA' || typeBlock === 'OUTD' ?
                                            <Box>
                                                <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                                    <Box className={classes.devicesLabel}>
                                                        {blockNodeSettings?.atributes_keys[0]?.name}
                                                    </Box>
                                                </Box>
                                                <Box width={'80%'}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            disabled={disableEditing}
                                                            name={blockNodeSettings?.atributes_keys[0]?.key}
                                                            value={stateForm?.a?.o ? stateForm?.a?.o : ''}
                                                            onChange={e => handleChange(e)}
                                                        >
                                                            {(typeBlock === 'OUTA' ? crearArrayValores(12, 13, 'A') : crearArrayValores(0, 11, 'P')).map((elem, indx) => (
                                                                <MenuItem
                                                                    key={indx}
                                                                    value={elem.key}
                                                                    disabled={disabledPin(elem.key)}
                                                                >
                                                                    {elem.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                {typeBlock === 'OUTA' &&
                                                    <Box>
                                                        <Box mt={2} display="flex" alignItems="center" flexWrap="wrap"  >
                                                            <Box className={classes.devicesLabel}>
                                                                Modo de funcionamiento
                                                            </Box>
                                                        </Box>
                                                        <Box width={'80%'}>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    disabled={disableEditing}
                                                                    name='m'
                                                                    value={stateForm?.a?.m ? stateForm?.a?.m : ''}
                                                                    onChange={e => handleChange(e)}
                                                                >
                                                                    {INA_OPTIONS.map((elem, indx) => (
                                                                        <MenuItem
                                                                            key={indx}
                                                                            value={elem.key}
                                                                        >
                                                                            {elem.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                            :
                                            <>
                                                {
                                                    (Array.isArray(blockNodeSettings?.atributes_keys) ? blockNodeSettings?.atributes_keys : []).map((element, indx) => {

                                                        return (
                                                            <Box key={indx} >
                                                                {
                                                                    element.name !== 'Overflow' ?
                                                                        < Box width={'80%'} mt={2} className={classes.input}>
                                                                            <TextField
                                                                                disabled={disableEditing}
                                                                                fullWidth
                                                                                type={returnTypeInput(element.name)}
                                                                                name={element.key}
                                                                                label={element.name}
                                                                                size='small'
                                                                                variant='outlined'
                                                                                value={stateForm?.a?.[element.key] ? stateForm?.a?.[element.key] : ''}
                                                                                onChange={e => handleChange(e)}
                                                                                color='primary'
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position='end'>{blockSecondTime() || element.name === 'Período' ? 'Segundos' : ''}</InputAdornment>,
                                                                                }}
                                                                            />

                                                                        </Box>
                                                                        :
                                                                        <>
                                                                            {blueLine}
                                                                            <Box mt={1} className={classes.devicesLabel}>
                                                                                {element.name}
                                                                            </Box>
                                                                            <Checkbox
                                                                                name={element.key}
                                                                                checked={stateForm?.a?.[element.key] !== undefined ? stateForm?.a?.[element.key] : ''}
                                                                                onChange={e => handleChange(e)}
                                                                                color="primary"
                                                                            />
                                                                        </>
                                                                }
                                                            </Box>
                                                        )
                                                    })

                                                }
                                            </>
                }
                {blueLine}

                {typeBlock === 'TCRON' && (
                    <Box>
                        Si desea conocer mas sobre las expresiones CRON, clickee <a href='https://es.wikipedia.org/wiki/Cron_(Unix)' target='_blank' rel="noreferrer">aquí</a>
                    </Box>
                )}

                <Box height={'20px'} >
                    {helpLabel}
                </Box>
                <Box height={'20px'} color='red'>
                    {errorLabel}
                </Box>
            </Box >
        </Box >
    )
}

export default GenericFilterOptions