import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
	devicesList: {
		margin: theme.spacing(1),
	},
	devicesTitle: {
		fontSize: '13px',
		color: theme.palette.grey.main,
		backgroundColor: theme.palette.grey.dark,
		padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
		borderRadius: theme.spacing(1),
		textAlign: 'center',
		margin: theme.spacing(1),
	},
	metersList: {
		background: theme.palette.background.default,
		padding: `${theme.spacing(0.5)}px ${theme.spacing(5)}px`,
		height: theme.spacing(12),
		overflowY: 'scroll',
	},
	meter: {
		borderBottom: `1px dashed ${theme.palette.grey.hairline}`,
		textAlign: 'center',
		fontSize: '15px',
		color: theme.palette.text.primary,
		paddingLeft: theme.spacing(1),
		cursor: 'pointer',
		'&:hover': {
			color: '#fff',
			background: theme.palette.primary.main,
		},
	},
	selectedMeter: {
		fontSize: '15px',
		textAlign: 'center',
		background: theme.palette.primary.main,
		color: '#fff',
	},
	active: {
		backgroundColor: theme.palette.primary.main,
		color: '#fff',
	},
	flechas: {
		padding: 0,
		margin: 'auto',
	},
	label: {
		fontSize: 16,
		fontWeight: 700,
		marginTop: theme.spacing(2),
	},
}));

export default function DevicesList({
	savedMeter, savedMeterName, selectedMeter, selectedMeterName,
	unassignedMeters, setSelectedMeter, setSelectedMeterName,
	selectedAutomate, setSelectedAutomates, selectedAutomates, nodeData, unassignedAutomates, setUnassignedAutomates
}) {
	const classes = useStyles();
	const [freeMeterSelected, setFreeMeterSelected] = useState({ id: 0, name: "" });
	const [freeAutomateSelected, setFreeAutomateSelected] = useState({ id: 0, name: "" });
	const [removeAutomateSelected, setRemoveAutomateSelected] = useState({});

	useEffect(() => {
		if (nodeData?.automates?.length > 0) {
			setSelectedAutomates(nodeData?.automates)
		}
		else {
			setSelectedAutomates([])
		}
		// eslint-disable-next-line
	}, [])

	const handleSelectedMeter = (meterId, meterName) => {
		setSelectedMeter(meterId);
		setSelectedMeterName(meterName);
	};

	const toggleSelected = (id, meterName, type) => {
		if (type === 'automate') {
			if (freeAutomateSelected.id !== id) {
				setFreeAutomateSelected({ id: id, name: meterName });
			}
			else {
				setFreeAutomateSelected({ id: 0, name: "" });
			}
		}
		else {
			if (freeMeterSelected.id !== id) {
				setFreeMeterSelected({ id: id, name: meterName });
			}
			else {
				setFreeMeterSelected({ id: 0, name: "" });
			}
		}
	};

	const toggleSelectedRemoveAutomate = (id, name) => {
		setRemoveAutomateSelected({
			nombre: name,
			id: id
		})
	};

	const handleSelectedAutomate = (id, name) => {
		let validate = selectedAutomates.find(e => e.id === id)
		if (!validate?.id) {
			let obj = {
				nombre: name,
				id: id
			}
			let filter = unassignedAutomates.filter(e => e.id !== id)
			setUnassignedAutomates(filter)
			setSelectedAutomates([
				...selectedAutomates,
				obj
			])
		}
	};

	const handleRemoveAutomate = (id) => {
		let findElementRemove = selectedAutomates.find(e => e.id === id)
		let filter = selectedAutomates.filter(e => e.id !== id)
		let obj = {
			nombre: findElementRemove?.nombre,
			id: findElementRemove?.id
		}
		setUnassignedAutomates([
			...unassignedAutomates,
			obj
		])
		setSelectedAutomates(filter)
		setRemoveAutomateSelected({})
	};

	return (
		<Box>
			{/* DISPOSITIVOS DE MEDICIÓN */}
			<Box>
				<Box component='label' display='block' className={classes.label}>
					Dispositivos de medición
				</Box>
				<Grid container spacing={1} justifyContent='center' alignItems='center' className={classes.DevicesList}>
					<Grid item xs={4} className={classes.devicesTitle}>
						Dispositivos libres:
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={4} className={classes.devicesTitle}>
						Dispositivo asignado:
					</Grid>
				</Grid>
				<Grid container spacing={1} justifyContent='center' alignItems='flex-start' className={classes.metersRow}>
					<Grid item xs={4} className={classes.metersList}>
						{savedMeter && selectedMeter !== savedMeter ? (
							<Box
								className={`${classes.meter} ${freeMeterSelected.id === savedMeter ? classes.active : null}`}
								onClick={() => toggleSelected(savedMeter, savedMeterName)}
							>
								{savedMeterName}
							</Box>
						) : null}
						{unassignedMeters
							? unassignedMeters.map(meter => {
								return selectedMeter !== meter.id ? (
									<Box
										className={`${classes.meter} ${freeMeterSelected.id === meter.id ? classes.active : null}`}
										key={meter.id}
										onClick={() => toggleSelected(meter.id, meter.nombre)}
									>
										{meter.nombre}
									</Box>
								) : null;
							})
							: null}
					</Grid>
					<Grid item xs={1}>
						<Box display='flex' alignItems='flex-start' flexDirection='column'>
							<IconButton
								aria-label='seleccionar'
								className={classes.flechas}
								onClick={() => {
									setFreeMeterSelected({ id: 0, name: "" });
									handleSelectedMeter(freeMeterSelected.id, freeMeterSelected.name);
								}}
								disabled={Boolean(selectedMeter) || !Boolean(freeMeterSelected.id)}
							>
								<ChevronRightIcon fontSize='inherit' />
							</IconButton>
							<IconButton
								aria-label='deseleccionar'
								className={classes.flechas}
								onClick={() => handleSelectedMeter(0, "")}
								disabled={!Boolean(selectedMeter)}
							>
								<ChevronLeftIcon fontSize='inherit' />
							</IconButton>
						</Box>
					</Grid>
					<Grid item xs={4}>
						{selectedMeter ? (
							<Box className={classes.selectedMeter}>{selectedMeterName}</Box>
						) : (
							<Box className={classes.selectedMeter}>Ningún dispositivo seleccionado</Box>
						)}
					</Grid>
				</Grid>
			</Box>

			<Box style={{
				backgroundColor: '#2586bc69',
				height: '1px ',
				width: ' 100 %',
				margin: '20px 0 20px 0',
			}}>			</Box>

			{/* DISPOSITIVOS AUTOMATES */}
			<Box>
				<Box component='label' display='block' className={classes.label}>
					Dispositivos automate
				</Box>
				<Grid container spacing={1} justifyContent='center' alignItems='center' className={classes.DevicesList}>
					<Grid item xs={4} className={classes.devicesTitle}>
						Dispositivos libres:
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={4} className={classes.devicesTitle}>
						Dispositivo asignado:
					</Grid>
				</Grid>
				<Grid container spacing={1} justifyContent='center' alignItems='flex-start' className={classes.metersRow}>
					<Grid item xs={4} className={classes.metersList}>

						{unassignedAutomates
							? unassignedAutomates.map(automate => {
								return selectedAutomate !== automate.id ? (
									<Box
										className={`${classes.meter} ${freeAutomateSelected.id === automate.id ? classes.active : null}`}
										key={automate.id}
										onClick={() => toggleSelected(automate.id, automate.nombre, 'automate')}
									>
										{automate.nombre}
									</Box>
								) : null;
							})
							: null}
					</Grid>
					<Grid item xs={1}>
						<Box display='flex' alignItems='flex-start' flexDirection='column'>
							<IconButton
								aria-label='seleccionar'
								className={classes.flechas}
								onClick={() => {
									setFreeAutomateSelected({ id: 0, name: "" });
									handleSelectedAutomate(freeAutomateSelected.id, freeAutomateSelected.name);
								}}
								disabled={Boolean(selectedAutomate) || !Boolean(freeAutomateSelected.id)}
							>
								<ChevronRightIcon fontSize='inherit' />
							</IconButton>
							<IconButton
								aria-label='deseleccionar'
								className={classes.flechas}
								onClick={() => handleRemoveAutomate(removeAutomateSelected.id)}
								disabled={!Boolean(removeAutomateSelected.id)}
							>
								<ChevronLeftIcon fontSize='inherit' />
							</IconButton>
						</Box>
					</Grid>
					<Grid item xs={4}>
						{selectedAutomates?.length > 0 ? (
							<>
								{
									selectedAutomates.map((element, indx) => {
										return (
											<Box
												ml={1}
												key={indx}
												className={`${classes.meter} ${removeAutomateSelected.id === element?.id ? classes.active : null}`}
												onClick={() => toggleSelectedRemoveAutomate(element.id, element.nombre)}

											>
												{element?.nombre}
											</Box>
										)
									})
								}

							</>

						) : (
							<Box className={classes.selectedAutomate}>Ningún dispositivo seleccionado</Box>
						)}
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
