import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
//Componentes
import Container from '../Elements/Container';
import TopNav from '../common/TopNav';
import AlertPlcTable from '../common/AlertPlcTable';
import AlertComponent from '../Elements/AlertComponent';
import { Alert } from '@mui/material';
import ConfirmationDialog from '../AlertsAndReports/ConfirmationDialog';
import LoadingComponentBlur from '../common/LoadingComponentBlur';
//Servicios
import { getDiagramList, deleteDiagram } from '../../services/diagrams';
import { putPlc_automate_24v_state_action, getPlc_automate_24v_list } from '../../services/plc_automate';

const useStyles = makeStyles(theme => ({
    table: {
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(0.25),
        },
    },
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    containerTables: {
        marginTop: theme.spacing(2),
        display: 'flex',
        width: '100%',
        '@media (max-width: 1220px)': {
            flexDirection: 'column'
        },
    }
}));

const Diagrams = () => {
    const classes = useStyles();
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [close, setClose] = useState(false);
    const [dataDiagramList, setDataDiagramList] = useState([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState();
    const [loadingSave, setLoadingSave] = useState(false)
    const [plcList, setPlcListt] = useState([]);

    const labelsTableHeader = [
        'Nombre ',
        'Fecha de última actualización',
        'Editar',
        'Eliminar',
    ];

    //Carga los diagramas del usuario.
    useEffect(() => {
        getDiagramListFtn()
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        const getPlc_automate_24vAux = async () => {
            let res = await getPlc_automate_24v_list()
            if (res?.data) {
                setPlcListt(res?.data)
            }
            setLoading(false)
        }
        getPlc_automate_24vAux()
    }, [])

    const labelsTableHeaderPlc = [
        'Nombre ',
        'Fecha de última actualización',
        'Ver',
    ];

    //Estados
    let RUNNING = 'RUNNING'
    let PAUSE = 'PAUSE'
    let STOP = 'STOP'
    //Acciones
    let INICIADO = 'INICIADO'
    let PAUSADO = 'PAUSADO'
    let DETENIDO = 'DETENIDO'

    const handleStateDiagramButton = async (id_automate, action) => {
        try {
            let flag = 0
            if (action === INICIADO) {
                flag = 0
            }
            if (action === DETENIDO) {
                flag = 1
            }
            if (action === PAUSADO) {
                flag = 2
            }
            let body = {
                'state': flag,
                'command': 'SET'
            }
            await putPlc_automate_24v_state_action(id_automate, body)

            let newPlcList = [...plcList].map((element) => {
                if (element?.id === id_automate) {
                    if (action === INICIADO) {
                        element.estado = RUNNING
                    }
                    if (action === DETENIDO) {
                        element.estado = STOP
                    }
                    if (action === PAUSADO) {
                        element.estado = PAUSE
                    }
                    return element
                }
                else {
                    return element
                }
            })
            setPlcListt(newPlcList)
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(false)
    }

    const handleDirectionDiagramPlc = async idAux => {
        history.push(`/plc/${idAux}`);
    };

    const getDiagramListFtn = async () => {
        try {
            let res = await getDiagramList()
            if (res?.data?.results) {
                setDataDiagramList(res?.data?.results)
            }
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setLoading(false)
    }

    const handleCreateDiagram = () => {
        history.push('/create-diagram')
    }

    const handleDirectionDiagram = async idAux => {
        history.push(`/diagram/${idAux}`);
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            setLoadingSave(true)
            await deleteDiagram(selectedAlert.id)
            getDiagramListFtn()
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setLoadingSave(false)
    }

    return (
        <>
            {/* Container loading */}
            <LoadingComponentBlur
                loadingState={loadingSave}
            />

            <TopNav titulo="Diagramas">
                <Grid container justifyContent="flex-end" alignItems="center">
                    {/* Nombre Localización */}
                    <Button
                        className={classes.btn}
                        type="button"
                        onClick={handleCreateDiagram}
                        size="small"
                        startIcon={<AddIcon />}
                    >
                        Nuevo diagrama
                    </Button>
                </Grid>
            </TopNav>

            {loading && (
                <Box display="flex" justifyContent="center" marginTop={5}>
                    <CircularProgress />
                </Box>
            )}

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <ConfirmationDialog
                isOpen={isDeleteDialogOpen}
                handleConfirm={handleDelete}
                handleCancel={() => setIsDeleteDialogOpen(false)}
                title={`¿Desea eliminar su diagrama? Este proceso es irreversible`}
                content='En caso de confirmar el diagrama sera eliminado'
            />

            {!loading &&
                <Container>
                    {dataDiagramList.length === 0 ?
                        <Alert severity="warning">
                            {'No posee ningun diagrama, puede crear uno a continuación haciendo click en el botón Nuevo diagrama.'}
                        </Alert>
                        :
                        <Box className={classes.containerTables}>
                            {dataDiagramList?.length > 0 &&
                                < AlertPlcTable
                                    title={'Diagramas'}
                                    diagramComponent={true}
                                    dataList={dataDiagramList}
                                    labelsTableHeader={labelsTableHeader}
                                    handleDirectionDiagram={handleDirectionDiagram}
                                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                                    setSelectedAlert={setSelectedAlert}
                                />}
                            {plcList?.length > 0 &&
                                <AlertPlcTable
                                    title={'Automate'}
                                    plcComponent={true}
                                    setPlcListt={setPlcListt}
                                    dataList={plcList}
                                    labelsTableHeader={labelsTableHeaderPlc}
                                    setIsDeleteDialogOpen={() => () => { }}
                                    handleStateDiagramButton={handleStateDiagramButton}
                                    handleDirectionDiagram={handleDirectionDiagramPlc}
                                />
                            }
                        </Box>
                    }
                </Container>
            }
        </>
    );
};

export default Diagrams;
