export const translateDayToSpanish = day => {
  switch (day) {
    case 'monday':
      return 'Lunes';
    case 'tuesday':
      return 'Martes';
    case 'wednesday':
      return 'Miércoles';
    case 'thursday':
      return 'Jueves';
    case 'friday':
      return 'Viernes';
    case 'saturday':
      return 'Sábado';
    case 'sunday':
      return 'Domingo';
    default:
      break;
  }
};

export const translateDayToAbbreviatedSpanish = day => {
  switch (day) {
    case 'monday':
      return 'lu';
    case 'tuesday':
      return 'ma';
    case 'wednesday':
      return 'mi';
    case 'thursday':
      return 'ju';
    case 'friday':
      return 'vi';
    case 'saturday':
      return 'sa';
    case 'sunday':
      return 'do';
    default:
      break;
  }
};

export const translateDayToEnglish = day => {
  switch (day) {
    case 'Lunes':
      return 'monday';
    case 'Martes':
      return 'tuesday';
    case 'Miércoles':
      return 'wednesday';
    case 'Jueves':
      return 'thursday';
    case 'Viernes':
      return 'friday';
    case 'Sábado':
      return 'saturday';
    case 'Domingo':
      return 'sunday';
    default:
      break;
  }
};

export const translateAbbreviatedDayToEnglish = day => {
  switch (day) {
    case 'lu':
      return 'monday';
    case 'ma':
      return 'tuesday';
    case 'mi':
      return 'wednesday';
    case 'ju':
      return 'thursday';
    case 'vi':
      return 'friday';
    case 'sa':
      return 'saturday';
    case 'do':
      return 'sunday';
    default:
      break;
  }
};

export const translateAbbreviatedDayToSpanish = day => {
  switch (day) {
    case 'lu':
      return 'Lunes';
    case 'ma':
      return 'Martes';
    case 'mi':
      return 'Miercoles';
    case 'ju':
      return 'Jueves';
    case 'vi':
      return 'Viernes';
    case 'sa':
      return 'Sabado';
    case 'do':
      return 'Domingo';
    default:
      break;
  }
};

export const getDayOrder = day => {
  switch (day) {
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
    case 'sunday':
      return 7;
    default:
      break;
  }
};