import { Box, Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, makeStyles, Snackbar, TextField, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import TuneIcon from '@material-ui/icons/Tune';
import DeleteIcon from '@material-ui/icons/Delete';
import DevicesList from './DevicesList';
import { Alert } from '@mui/material';
import { createApiKey, deleteAllApiKeys, deleteApiKey, getApiKeys } from '../../services/externals';
import ConfirmationDialog from '../AlertsAndReports/ConfirmationDialog';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CountdownTimer from '../common/CountdownTimer';
import HelpIcon from '@mui/icons-material/Help';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getStatusNodes } from '../../services/meters';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useDeviceDetect from '../../hooks/useDeviceDetect';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(8),
    "@media (max-width: 700px)": {
      marginLeft: 0
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    "@media (max-width: 700px)": {
      width: "200px",
    },
    marginLeft: 10
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    background: 'white',
    "& .MuiGrid-item": {
      textAlign: 'center'
    },
    "@media (max-width: 700px)": {
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      flexDirection: 'column',
    },
  },
  nodeList: {
    listStyleType: 'none',
    textAlign: 'center',
    padding: 0,
    margin: 0,
    "& li": {
      display: 'inline',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontWeight: 'bold',
      "@media (max-width: 700px)": {
        display: 'block'
      }
    }
  },
  btns: {
    marginLeft: 'auto',
    display: 'flex',
    "@media (max-width: 700px)": {
      marginLeft: 'inherit'
    }
  },
  containerUid: {
    display: 'flex',
    alignItems: 'center'
  },
  labelUid: {
    cursor: 'pointer',
    '&:hover': {
      color: '#add4e7',
    },
  },
  uid: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '200px'
  },
  labelDevice: {
    fontSize:17
  },
  rowInactiveApiKey: {
    padding: theme.spacing(2),
    display: 'flex',
    color: '#292929',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    alignItems: 'center',
    background: '#e0e2e3',
    flexWrap: 'wrap',
    "& .MuiGrid-item": {
      textAlign: 'center'
    },
    "@media (max-width: 700px)": {
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      flexDirection: 'column',
    },
  },
  totalKeysLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    color: '#292929',
    marginTop: '10px'
  },
  labelSearch: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: theme.spacing(2),
  },
}));

export function IntegrationsCreator() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success"
  })
  const [showApiKeyModalTitle, setShowApiKeyModalTitle] = useState("");
  const [apiKeys, setApiKeys] = useState([]);
  const [filteredApiKeys, setFilteredApiKeys] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [isShowApiKeyModalOpen, setIsShowApiKeyModalOpen] = useState(false);
  const [createdApiKey, setCreatedApiKey] = useState(null);
  const [statusNodes, setStatusNodes] = useState(null);
  const [openInactiveApiKeys, setOpenInactiveApiKeys] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    fetchApiKeys();
    fetchStatusData();
  }, []);

  const fetchStatusData = async () => {
    try {
      const resStatus = await getStatusNodes();
      setStatusNodes(resStatus.data);
    }
    catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchApiKeys = async () => {
    try {
      setLoading(true);  
      setInputSearch("")
      const res = await getApiKeys();
      const aux = res.data.map(apiKey => {
        return {
          id: apiKey?.id,
          uid: apiKey?.uid,
          name: apiKey?.jerarquia?.nombre,
          valid: apiKey?.valid,
          creationDate: apiKey?.creation_formatted
        }
      }
      )
      setApiKeys(aux);
      setFilteredApiKeys(aux)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleCreate = async (node) => {
    try {
      setLoading(true);
      let selectedHierarchies = handleSelectedHierarchy(node)
      const res = await createApiKey(generateHierarchyQuery(selectedHierarchies));
      // setHierarchies([])
      setLoading(false);
      setCreatedApiKey(res.data?.uid)
      setIsShowApiKeyModalOpen(true);
      setShowApiKeyModalTitle('API-Key Creada correctamente')
      setNotification({
        isOpen: true,
        msg: res.data?.msg,
        severity: "success"
      })
      fetchApiKeys();
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response) {
        setNotification({
          isOpen: true,
          msg: `${error.response.data.msg} (${error.response.data.error_code}).`,
          severity: "error"
        })

      } else {
        setNotification({
          open: true,
          message: `Ocurrió un error inesperado.`,
          severity: "error"
        })
      }
    }
  }

  const generateHierarchyQuery = (hierarchies) => {
    let query = ""

    for (let i = 0; i < hierarchies.length; i++) {
      if (i > 0) {
        query += '&'
      }
      query += hierarchies[i].id
    }

    return Number(query);
  }

  const handleDeleteApiKey = async () => {
    try {
      if (selectedApiKey) {
        setLoading(true);
        await deleteApiKey(selectedApiKey.uid);
        setIsDeleteDialogOpen(false);
        setLoading(false);
        setNotification({
          isOpen: true,
          msg: 'Api-Key eliminada correctamente',
          severity: "success"
        })
        await fetchApiKeys();
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.msg) {
        setNotification({
          isOpen: true,
          msg: `${error.response.data.msg} (${error.response.data.error_code}).`,
          severity: "error"
        })

      } else {
        setNotification({
          isOpen: true,
          msg: `Ocurrió un error inesperado.`,
          severity: "error"
        })
      }
    }
  }

  const handleClose = () => {
    setModalIsOpen(false);
  }

  const handleSelectedHierarchy = (node) => {
    let selectedHierarchies = [{ nombre: node.nombre, id: node.id, hijos: node.childrenList }]
    setModalIsOpen(false);
    return selectedHierarchies;
  }

  const handleDeleteAll = async () => {
    try {
      await deleteAllApiKeys();
      setIsDeleteAllDialogOpen(false);
      setNotification({
        isOpen: true,
        msg: 'API-Keys eliminadas correctamente',
        severity: "success"
      })
      await fetchApiKeys();
    } catch (error) {
      if (error.response.data.msg) {
        setNotification({
          isOpen: true,
          msg: `${error.response.data.msg} (${error.response.data.error_code}).`,
          severity: "error"
        })
      } else {
        setNotification({
          isOpen: true,
          msg: `Ocurrió un error inesperado.`,
          severity: "error"
        })
      }
    }
    setModalIsOpen(false);
  }

  const handleDeleteDialog = (apiKey) => {
    if (apiKey) {
      setSelectedApiKey(apiKey);
      setIsDeleteDialogOpen(true);
    }
  }

  const handleCopyUid = () => {
    if (createdApiKey) {
      navigator.clipboard.writeText(createdApiKey);
      setNotification({
        isOpen: true,
        msg: `API-Key copiada`,
        severity: "success"
      })
    }
  }

  const handleShowApiKey = (apiKey) => {
    if (apiKey) {
      setCreatedApiKey(apiKey.uid)
      setIsShowApiKeyModalOpen(true);
      setShowApiKeyModalTitle('API-Key')
    }
  }

  const inactiveApiKeys = () => {
    let inactiveKeys = apiKeys.filter(item => item.valid === false);
    return inactiveKeys
  }

  const activeApiKeys = () => {
    let activeKeys = filteredApiKeys.filter(item => item.valid === true);
    return activeKeys;
  }

  const handleInputSearch = (e) => {
    setInputSearch(e.target.value)
    let valueInputLowercase = e.target.value.toLowerCase().trim();
    let listFiltered = apiKeys.filter(key => {
      let nameKey = key.name.toLowerCase();
      if ((nameKey.includes(valueInputLowercase))) {
        return nameKey;
      }
      return null
    }
    )
    setFilteredApiKeys(listFiltered);
  };

  return (
    <Box
      className={classes.container}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      px={2}
      py={1}
    >
      <Box component="h3" color="primary.main" ml={0.5} my={0.5}>
        Gestión de API-Keys
      </Box>

      <Box
        bgcolor="background.paper"
        color="primary.main"
        p={1}
        borderRadius={4}
      >

        <Box component="h4" my={1}>
          Nueva API-Key
        </Box>
        <Box display="flex" justifyContent={'center'} mt={3} mb={3} mx="auto" >
          <Button
            color="primary"
            variant="contained"
            onClick={() => setModalIsOpen(true)}
            startIcon={<TuneIcon />}
          >
            Agregar dispositivo
          </Button>
        </Box>
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" marginTop={10}>
          <CircularProgress />
        </Box>
      )}

      {!loading && apiKeys.length > 0 && (
        <Box
          bgcolor="background.paper"
          color="primary.main"
          p={1}
          borderRadius={4}
        >
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} component="h4" my={1}>
            API-Keys Existentes
            <Button
              color="primary"
              variant="contained"
              startIcon={<DeleteIcon />}
              aria-label="Eliminar Api-Key"
              disabled={activeApiKeys().length === 0}
              onClick={() => setIsDeleteAllDialogOpen(true)}
            >
              Eliminar todo
            </Button>
          </Box>
          <Box my={4} mx={1}>
              <Box component="label" display="block" className={classes.labelSearch}>
                Buscador
              </Box>
              <TextField
                fullWidth
                placeholder="Ingrese nombre de dispositivo"
                name="buscador"
                value={inputSearch}
                onChange={handleInputSearch}
                color="primary"
                className={classes.input}
              />
            </Box>
          <Box >
           {(activeApiKeys()?.length > 0 && !isMobile) && <Box style={{color:'black'}} display={'flex'} padding={'8px'}>
              <Box style={{ minWidth: '30%' }}>Dispositivo</Box>
              <Box style={{ minWidth: '30%' }}>Fecha de creación</Box>
              <Box style={{ minWidth: '30%' }}>Key</Box>
            </Box>}
            <Box marginTop={2}>
              {activeApiKeys()?.map(
                (apiKey) => (
                  <Box className={classes.row} key={apiKey.id}>
                    <Box className={classes.labelDevice} style={{ minWidth: '30%'}}>{apiKey.name}</Box>
                    <Box style={{ minWidth: '30%' }}>{apiKey.creationDate}</Box>
                    <Box style={{ minWidth: '30%' }} className={classes.containerUid}>
                      <Tooltip title="Ver Api-Key"
                        arrow
                        placement="right">
                        <Box className={classes.labelUid} onClick={() => handleShowApiKey(apiKey)} display={'flex'}>
                          <Box className={classes.uid}> {apiKey.uid} </Box>
                          <VisibilityOutlinedIcon />
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box className={classes.btns}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        mx="auto"
                        p={1}
                      >
                        <Box mr={1}>
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            aria-label="Eliminar Api-Key"
                            onClick={() => handleDeleteDialog(apiKey)}
                          >
                            Eliminar
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          </Box>
          <Box className={classes.totalKeysLabel}>
            Total: {activeApiKeys().length}
          </Box>

          <Box marginTop={5}>
            <ListItem
              button
              onClick={() => setOpenInactiveApiKeys(!openInactiveApiKeys)}
              className={classes.rowInactiveApiKey}
            >
              <ListItemText primary={'API-Keys inactivas'} />
              <Box style={{color: '#292929'}}>Total: {inactiveApiKeys().length}</Box>
              {(openInactiveApiKeys ? (
                <IconButton edge='end' aria-label='contraer' onClick={() => setOpenInactiveApiKeys(true)}>
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton edge='end' aria-label='expandir' onClick={() => setOpenInactiveApiKeys(false)}>
                  <ExpandMoreIcon />
                </IconButton>
              ))}
            </ListItem>
          </Box>


          {
            inactiveApiKeys() ? (
              <Collapse in={openInactiveApiKeys} timeout='auto' unmountOnExit className={classes.nested}>
                <List component='div' disablePadding>
                  {inactiveApiKeys().map((apiKey) => (
                    <Box style={{borderBottom: '1px solid #8f8f91',borderTop: '1px solid #8f8f91',}} className={classes.rowInactiveApiKey} key={apiKey.id}>
                      <Box >Dispositivo: {apiKey.name}</Box>
                      <Tooltip title="Ver Api-Key"
                        arrow
                        placement="right">
                        <Box onClick={() => handleShowApiKey(apiKey)}>
                          <Box > {apiKey.uid} </Box>
                        </Box>
                      </Tooltip>
                    </Box>
                  ))}
                </List>
              </Collapse>
            ) : null
          }
        </Box>
      )}

      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        handleConfirm={handleDeleteApiKey}
        handleCancel={() => setIsDeleteDialogOpen(false)}
        title={`¿Desea eliminar la API-Key '${selectedApiKey?.name}'?`}
        content='En caso de confirmar la API-Key será eliminada'
      />

      <ConfirmationDialog
        isOpen={isDeleteAllDialogOpen}
        handleConfirm={handleDeleteAll}
        handleCancel={() => setIsDeleteAllDialogOpen(false)}
        title={`¿Desea eliminar todas las API-Keys?`}
        content='En caso de confirmar se eliminaran todas las API-Keys'
      />

      <DevicesList
        open={modalIsOpen}
        handleClose={handleClose}
        handleSubmit={handleCreate}
        fromIntegrations={true}
        statusNodes={statusNodes}
      />

      <Snackbar
        open={notification.isOpen}
        autoHideDuration={7000}
        onClose={() => setNotification({ ...notification, isOpen: false })}
      >
        <Alert
          severity={notification.severity}
          onClose={() => setNotification(false)}
        >
          {notification.msg}
        </Alert>
      </Snackbar>

      <Dialog
        open={isShowApiKeyModalOpen}
        onClose={() => setIsShowApiKeyModalOpen(false)}
        aria-labelledby='form-dialog-title'
        maxWidth='sm'
        fullWidth
      >
        <Box padding={1}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <DialogTitle>{showApiKeyModalTitle}</DialogTitle>
            {
              showApiKeyModalTitle === 'API-Key' &&
              <Box style={{ marginRight: 5 }}>
                <CountdownTimer initialTime={15} size={60} onComplete={() => setIsShowApiKeyModalOpen(false)} />
              </Box>
            }
          </Box>

          <DialogContent>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <TextField
                disabled={true}
                label="Api-Key uid"
                name="apiKey"
                fullWidth
                color="primary"
                variant="outlined"
                size="small"
                value={createdApiKey || ''}
              />
              <Tooltip
                title="Copiar uid"
                arrow
                placement="right"
              >
                <Button
                  style={{ marginLeft: '10px', marginRight: '10px', height: '40px' }}
                  size='small'
                  variant='contained'
                  onClick={handleCopyUid}
                >
                  <FileCopyIcon style={{ marginRight: '2px', fontSize: '17px' }} />
                </Button>
              </Tooltip>
            </Box>
            <Box marginTop={2} display={'flex'} alignItems={'start'}>
              <HelpIcon style={{ fontSize: '20px' }} color='primary' />
              <Box marginLeft={'5px'}>La API key debe incluirse en los headers de las requests HTTPS como en este ejemplo:</Box>
            </Box>
            <Box marginLeft={'5px'} marginTop={1} style={{ border: '1px solid #797979' }} padding={2}>
              Host: https://powermate-api.powermeter.com.ar/external/summary  <br />
              Content-Type: application/x-www-form-urlencoded<br />
              Content-Length: 27 <br />
              <b>x-api-key</b>: {createdApiKey}
            </Box>
            <Box marginTop={2} marginLeft={'5px'}>
              Clickee <a href='https://powermeter.gitbook.io/api-externa/' rel='noopener noreferrer' target='_blank'>aquí</a> para ver una guía detallada.
            </Box>
            <Box marginTop={2} display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                onClick={() => setIsShowApiKeyModalOpen(false)}
                color='primary'
              >
                Aceptar
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
}
