
import { useEffect, useState, } from 'react';

import {
    Box, MenuItem,
    makeStyles, Select, FormControl,
    TextField, InputAdornment
} from '@material-ui/core';

//Helpers.
import { blueBar } from '../../../helpers/common'
import { getSettingBlock } from '../helpers';
import { MarkdownRenderer } from './helperDescriptionText';

let scrollsSettings = {
    overflow: 'scroll',
    overflowX: 'hidden',
};
let webkitScrollbar = {
    background: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#beb1b18f',
    color: 'red',
    width: '9px',
};
let webkitscrollbarTrack = {
    background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
    root: {
        ...scrollsSettings,
        height: '600px',
        '@media (max-width: 920px)': {
            height: '70%',
        },
        '&::-webkit-scrollbar': {
            //son los estilos de las barras scroll en una variable.
            ...webkitScrollbar,
        },
        '&::-webkit-scrollbar-track': {
            //son los estilos de las barras scroll en una variable.
            ...webkitscrollbarTrack,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
        },
        '& .MuiStepper-root': {
            padding: '1px',
        },
        '& .MuiFormControlLabel-root': {
            marginRight: theme.spacing(0.5),
        },
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    input: {
        '& .MuiInputBase-input': {
            // Saco las flechitas al input type number
            '&::-webkit-inner-spin-button': {
                webkitAppearance: "none",
                mozAppearance: "none",
                appearance: " none",
                margin: 0
            },
        },
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.primary.dark,
    },
}));

const DynamicFilterOptions = ({ blockNodeSettings, varState, setVarState, typeBlock, userConfiguration, disableEditing }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({})
    const BOOL_OPTIONS = [
        {
            name: 'Verdadero',
            key: 'true'
        },
        {
            name: 'Falso',
            key: 'false'
        },
    ]

    useEffect(() => {
        if (varState?.a) {
            let objAux = { ...varState?.a }
            if (objAux?.p) {
                objAux.p = objAux.p /= 100;
            }
            setStateForm(objAux)
        }
    }, [])

    useEffect(() => {
        let objAux = { ...stateForm }
        // valido los datos q son centisegundos
        if (objAux?.p) {
            objAux.p = transformUnit(objAux?.p)
        }
        setVarState({
            ...varState,
            a: objAux
        })
        // eslint-disable-next-line
    }, [stateForm])

    const handleChange = (e) => {
        let { value, name } = e.target;
        setStateForm({
            ...stateForm,
            [name]: value
        })
    }

    //Devuelve el tipo de valor q es en el input segun la propiedad.
    const returnTypeInput = (name) => {
        if (name === 'Nombre' || name === 'Unidad') {
            return 'text'
        }
        return 'number'
    }

    const returnLabelInput = (nameAux) => {
        if (nameAux === 'Periodo de trabajo del PID') {
            return 'Segundos'
        }
    }

    // Convierte a entero sin signo de 32 bits
    function transformUnit(value) {
        let resultado = value * 100;
        resultado = Math.round(resultado);
        return resultado.toString()
    }

    return (
        <Box>
            {/* TITULO */}
            <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>{userConfiguration ? `${getSettingBlock(typeBlock)?.name}` : `${getSettingBlock(typeBlock)?.name}`}</h3>
            </Box >

            {blueLine}

            <Box mb={2} >
                <Box className={classes.content} ml={1}>
                    <MarkdownRenderer markdown={blockNodeSettings?.description ? blockNodeSettings?.description : ''} />
                </Box>
            </Box>

            {blueLine}

            <>
                {
                    (Array.isArray(blockNodeSettings?.atributes_keys) ? blockNodeSettings?.atributes_keys : []).map((element, indx) => {

                        return (
                            <Box key={indx} >

                                {
                                    element?.name === 'Inversión de lógica' ?
                                        <>
                                            <Box mt={2}>
                                                {/* STEP 1 */}
                                                <Box display="flex" alignItems="center" flexWrap="wrap"  >
                                                    <Box className={classes.devicesLabel}>
                                                        {element.name}
                                                    </Box>
                                                </Box>
                                                <Box width={'80%'}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            disabled={disableEditing}
                                                            name={element.key}
                                                            value={stateForm?.[element.key] ? stateForm?.[element.key] : ''}
                                                            onChange={e => handleChange(e)}
                                                        >
                                                            {BOOL_OPTIONS.map((elem, indx) => (
                                                                <MenuItem
                                                                    key={indx}
                                                                    value={elem.key}
                                                                >
                                                                    {elem.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </>
                                        :
                                        < Box width={'80%'} mt={2} className={classes.input}>
                                            <TextField
                                                disabled={disableEditing}
                                                fullWidth
                                                type={returnTypeInput(element.name, element?.key)}
                                                name={element.key}
                                                label={element.name}
                                                size='small'
                                                variant='outlined'
                                                value={stateForm?.[element.key] ? stateForm?.[element.key] : ''}
                                                onChange={e => handleChange(e, element.name)}
                                                color='primary'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position='end'>{returnLabelInput(element.name)}</InputAdornment>,
                                                }}
                                            />
                                        </Box>
                                }

                            </Box>
                        )
                    })

                }
            </>
        </Box >
    )
}
export default DynamicFilterOptions





