
import { useContext, useState, useEffect } from 'react';
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import { Box, makeStyles, Tooltip, } from '@material-ui/core';
//Hook personalizado.
import DiagramContext from '../Store/DiagramContext';
//Helper
import { getSettingBlock, getCatalogo } from '../helpers';
//Components.
import ModalSettings from '../ModalSettings';
//React flow
import { useEdges, useNodes } from 'reactflow';

let styleAux = {
    width: `85px`,
    height: `85px`,
}

const useStyles = makeStyles(theme => ({
    root: {
        ...styleAux,
        padding: '3px',
        backgroundColor: '#f5f5f5',
        border: `1px solid ${`black`}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    disabledStyle: {
        ...styleAux,
        pointerEvents: 'none',
        opacity: 0.5,
        backgroundColor: '#f0f0f0',
        color: '#a0a0a0',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function ContainerBlockGenericType
    ({ id, isConnectable, type, selected,
        adderFlag, mTopValid, userConfiguration,
    }) {

    const classes = useStyles();
    const edges = useEdges();
    const nodes = useNodes()
    const { stateDiagram, setStateDiagram } = useContext(DiagramContext)
    const [blockNodeFilter, setBlockNodeFilter] = useState({});
    const [blockNodeSettings, setBlockNodeSettings] = useState({});
    const [openModal, setOpenModal] = useState(false)
    const [disabledNode, setDisabledNode] = useState(false);
    const [errorNode, setErrorNode] = useState(false);
    const [sourcePosition, setSourcePosition] = useState(Position.Right)
    const [targetPosition, setTargetPosition] = useState(Position.Left)
    const updateNodeInternals = useUpdateNodeInternals();
    let flagNumberTopInputs = 20

    //Estado para buscar los datos de la propiedad 'a' para mostrar en el bloque.
    useEffect(() => {
        let findElement = nodes.find((elem) => elem.id === id)
        // console.log(findElement)
        setBlockNodeFilter(findElement)
        // eslint-disable-next-line
    }, [nodes])

    useEffect(() => {
        let res = getSettingBlock(type)
        setBlockNodeSettings(res)
        // eslint-disable-next-line
    }, [])

    //Deshabilita el nodo segun el nodo el cual se esta conectando.
    useEffect(() => {
        let res = stateDiagram?.disabledNodes?.includes(type)
        setDisabledNode(res)
        // eslint-disable-next-line
    }, [stateDiagram?.disabledNodes])

    // Cambia el bloque a modo error (En el bloque hay un error de configuración)
    useEffect(() => {
        let res = stateDiagram?.errorBlockNodes?.includes(id)
        setErrorNode(res)
        // eslint-disable-next-line
    }, [stateDiagram?.errorBlockNodes])

    const handleOpenModal = () => {
        //Guardo el estado de el modal para desactivar el cntr + V
        setStateDiagram({
            ...stateDiagram,
            openModal: !openModal
        })
        setOpenModal(!openModal)
    }

    const checkTargetConnect = (idAux, elementAux) => {
        let flag = true
        for (let i = 0; i < edges.length; i++) {
            const element = edges[i];
            if (element.target === idAux) {
                if (element.targetHandle === elementAux.key) {
                    flag = false
                }
            }
        }
        return flag
    }

    const validType = () => {
        if (type === 'OUTA' || type === 'OUTD') {
            return false
        }
        return true
    }

    const onDoubleClickAux = () => {
        if (!openModal) {
            handleOpenModal();
        }
    }

    const createArray = (n) => {
        return Array.from({ length: n }, (v, i) => i);
    }

    return (
        <>
            <Box>
                <Box
                    className={disabledNode ? classes.disabledStyle : classes.root}
                    key={id}
                    style={{
                        // transform: `rotate(${degRotate}deg)`,
                        border: selected ? `1px dashed ${errorNode ? '#f8000075' : '#2586bc'}` : `1px solid ${errorNode ? '#f8000075' : 'black'}`,
                        backgroundColor: errorNode ? 'rgba(248, 0, 0, 0.1)' : '',
                    }}
                    onDoubleClick={onDoubleClickAux}
                >

                    {openModal &&
                        <ModalSettings
                            type={type}
                            blockNodeSettings={blockNodeSettings}
                            handleOpenModal={handleOpenModal}
                            openModal={openModal}
                            userConfiguration={userConfiguration}
                            id={id}
                        />
                    }


                    <Box fontSize={11}  >
                        <Box fontSize={9} display={'flex'} justifyContent={'center'}>
                            {`${type}`}
                        </Box>
                        <Box mt={mTopValid ? mTopValid : 0} display={'flex'}>
                            <img src={`/images/diagram/${type.toLowerCase()}.svg`} alt="" width="35" height="35" />
                        </Box>
                    </Box>

                    {validType() &&
                        <>
                            {createArray(blockNodeSettings?.outputs_min).map((elem, indx) => {
                                return (
                                    <Tooltip
                                        key={indx}
                                        title={'Salida'}
                                        arrow
                                        placement='right'
                                    >
                                        <Handle
                                            type="source"
                                            position={sourcePosition}
                                            isConnectable={isConnectable}
                                            id="1"
                                            style={{ width: 8, height: 8, backgroundColor: '#1d1c33d1' }}
                                        />
                                    </Tooltip>
                                )
                            })}
                        </>
                    }
                    <Box >
                        {(Array.isArray(blockNodeSettings?.inputs_keys) ? blockNodeSettings.inputs_keys : []).map((element, index) => {
                            let flagAux = flagNumberTopInputs
                            flagNumberTopInputs += adderFlag ? adderFlag : 11
                            // console.log(flagAux)
                            return (
                                <Box key={element.key} >
                                    <Tooltip
                                        title={element?.name}
                                        arrow
                                        placement='right'
                                    >
                                        <Handle
                                            key={index}
                                            type="target"
                                            position={type === 'MUX2' && element.key === 's' ? Position.Bottom : targetPosition}
                                            id={element.key}
                                            isConnectable={disabledNode ? false : isConnectable}

                                            style={{
                                                top: type === 'MUX2' && element.key === 's' ? 82 : flagAux,
                                                position: 'fixed',
                                                display: 'block',
                                                width: 7,
                                                height: 7,
                                                border: `1px solid ${`black`}`,
                                                backgroundColor: checkTargetConnect(id, element) ? 'white' : '#b7b4b4'
                                            }}
                                        >
                                            <Box style={{
                                                fontSize: '8px',
                                                position: 'relative',
                                                right: type === 'MUX2' && element.key === 's' ? '0px' : (targetPosition === 'left' ? '-8px' : '8px'),
                                                bottom: type === 'MUX2' ? (element.key === 's' ? '11px' : '2px') : '2px',
                                            }}>
                                                {element.key}
                                            </Box>

                                        </Handle>
                                    </Tooltip>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </Box >

            {/* //Botones de giro */}
            {/* {
                selected &&
                <Box display='flex' justifyContent='center' width={'100%'} position={'absolute'}>

                    <Tooltip
                        title={'Invertir'}
                        arrow
                        placement="right"
                    >
                        <IconButton
                            style={{
                                width: '11px',
                                height: '11px',
                            }}
                            onClick={() => handleFlip()}
                            color="primary" >
                            <CompareArrowsIcon style={{ fontSize: '14px', color: '#2586bcc4' }} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={'Duplicar'}
                        arrow
                        placement="right"
                    >
                        <IconButton
                            style={{
                                width: '11px',
                                height: '11px',
                            }}


                            color="primary" >
                            <ContentCopyIcon style={{ fontSize: '13px', color: '#2586bcc4' }} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={'Girar'}
                        arrow
                        placement="right"
                    >
                        <IconButton
                            style={{
                                width: '11px',
                                height: '11px',
                            }}
                            onClick={() => handleRotate()}
                            color="primary" >
                            <RefreshIcon style={{ fontSize: '14px', color: '#2586bcc4' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            } */}
        </>
    );
}
export default ContainerBlockGenericType
