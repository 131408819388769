import React, { useState, useContext, useCallback, useEffect } from 'react';

import clsx from 'clsx';

import {
  Box, Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SpeedIcon from '@material-ui/icons/Speed';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import { postNode } from '../../services/hierarchy';
import UserContext from '../../context/UserContext';
import NodesListContext from '../../context/NodesListContext';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import SyncAltSharpIcon from '@mui/icons-material/SyncAltSharp';
import DragDropList from './DragDropList';
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SaveAsIcon from '@mui/icons-material/SaveAs';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import useDeviceDetect from "../../hooks/useDeviceDetect";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
    '@media (max-width: 700px)': {
      position: 'relative',
      left: '-32px',
      width: 'calc(100% + 32px)',
    },
  },
  selected: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    '&.MuiListItem-root.Mui-selected p': {
      color: theme.palette.common.white,
    },
  },
  nodeHeader: {
    display: 'flex',
    marginRight: '100px',
    '& span': {
      fontSize: 'larger',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& p': {
      marginLeft: '5px',
      fontSize: 'larger',
    },
    '@media (max-width: 700px)': {
      flexDirection: ' column',
      '& span': {
        marginLeft: '5px',
        fontSize: 'small',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& p': {
        marginLeft: '5px',
        fontSize: 'small',
      },
    },
    '@media (max-width: 405px)': {
      maxWidth: '30%'
    },
    '@media (max-width: 347px)': {
      maxWidth: '20%'
    },
    '@media (min-width: 960px) and (max-width: 1338px)': {
      maxWidth: '40%'
    }
  },
  empresa: {
    '@media (max-width: 700px)': {
      // backgroundColor: theme.palette.primary.main,
      height: '50px',
      padding: '10px',
      marginBottom: '1px',
      // color: '#e0e0e0',
    },
  },
  zona: {
    '@media (max-width: 700px)': {
      // background: '#c1c1c1',
      height: '50px',
      padding: '10px',
      marginBottom: '1px',
    },
  },
  localizacion: {
    '@media (max-width: 700px)': {
      // background: '#e0e0e0',
      height: '50px',
      padding: '10px',
      marginBottom: '1px',
    },
  },
  sublocaclizacion: {
    '@media (max-width: 700px)': {
      // background: '#f1efef',
      height: '50px',
      padding: '10px',
      marginBottom: '1px',
    },
  },
  meterWithoutChild: {
    // marginRight: 14,
  },
  buttonEditPositions: {
    backgroundColor: '#2586bc',
    borderRadius: '25%',
    padding: 2,
    marginRight: 1,
    boxShadow: '1px 1px 1px #cbc1c157',
    border: "1px solid #cbc1c157",
    color: '#efefef',
    '&:hover': {
      backgroundColor: '#3AA5D2'
    }
  }
}));

export default function Node({ setNewChangesToPositions, setClose, node, selectedNode, handleSelectedNode, setTreeChanges, status, setFlagEdit, flagEdit, collapse }) {
  const classes = useStyles();
  const { plan } = useContext(UserContext)
  const { nodesList } = useContext(NodesListContext);
  const [addNodoAlert, setAddNodoAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const nodeStatus = status?.find(n => n.node_id === node.id)
  const [edit, seTedit] = useState('');
  const [collapseChildrens, setCollapseChildrens] = useState('');
  const { isMobile } = useDeviceDetect(450);

  const handleArrows = () => {
    setOpen(!open);
  };

  useEffect(() => {
    seTedit('')
    if (flagEdit?.node_id === node.id) {
      seTedit(node.level_nombre)
    }
    else {
      seTedit('')
    }
    // eslint-disable-next-line
  }, [flagEdit])

  useEffect(() => {
    if (collapse) {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse])

  const handleAdd = async node => {
    const family = [
      {
        self: 'raiz',
        child: 'Empresa',
      },
      {
        self: 'empresa',
        child: 'Zona',
      },
      {
        self: 'zona',
        child: 'Localización',
      },
      {
        self: 'localización',
        child: 'Sublocalización',
      },
      {
        self: 'sublocalización',
        child: 'Extra',
      },
    ];

    let nombreHijo = '';

    family.forEach(member => {
      if (member.self === node.level_nombre) nombreHijo = member.child;
    });

    try {
      await postNode({
        parent: node.id,
        nombre: `Nueva ${nombreHijo}`,
        descripcion: 'Nuevo',
        medidor_virtual: true,
        medidor: null,
      });
      // console.log(res.data);
      setTreeChanges(current => !current);
    } catch (error) {
      console.error(error);
    }
  };
  const alertNodo = () => {
    setAddNodoAlert(true);
    setTimeout(() => {
      setAddNodoAlert(false);
    }, 2000);
  };

  const classNodeSelect = node => {
    if (node.level_nombre === 'empresa') {
      return classes.empresa;
    } else if (node.level_nombre === 'zona') {
      return classes.zona;
    } else if (node.level_nombre === 'localización') {
      return classes.localizacion;
    } else if (node.level_nombre === 'sublocalización') {
      return classes.sublocaclizacion;
    } else {
      return '';
    }
  };

  const checkDevice = (node) => {
    let children = node?.hijos?.length > 1;
    return children
  }

  // Función encargada de retornar el número de hijos que tiene una cierra localización.
  // Si la invoco con "empresa" devolverá la cantida de zonas que tengo.
  // empresa -> retorno la cantidad de zonas
  // zona -> retorno la cantidad de localizaciones
  // localización -> en vez de retornar la cantida de sublocalizaciones, retorno 0 (haciendo de cuenta que no tengo ninguna sublocalización pues admito infinitas)
  // sublocalización -> en vez de retornar la cantida de extras, retorno 0 (haciendo de cuenta que no tengo ninguna extra pues admito infinitas)

  const getTotalOfChildNodes = useCallback(parent => {


    if (parent === 'localización' || parent === 'sublocalización' || parent === 'extra') {
      return 0;
    }

    let childNode = '';
    switch (parent) {
      case 'empresa':
        childNode = 'zona';
        break;
      case 'zona':
        childNode = 'localización';
        break;
      case 'localización':
        childNode = 'sublocalización';
        break;

      // sublocalización
      // extra
      // retornan 0, como admito infinitas hago de cuenta que no tengo ninguna así el max_locations = 1 siempre me permite seguir creando.

      default:
        childNode = 'raiz'
        break;
    }

    return nodesList.filter(node => node?.level_nombre === childNode).length;
  }, [nodesList]);

  // Devolver el plural de la jerarquía hija
  // empresa -> zonas
  // zona -> localizaciones
  // localización -> sublocalizaciones
  // sublocalización -> extras
  const getPluralOfChild = type => {
    switch (type) {
      case 'empresa':
        return 'zonas';

      case 'zona':
        return 'localizaciones';

      case 'localización':
        return 'sublocalizaciones'

      case 'sublocalización':
        return 'extras'

      default:
        return 'extras'
    }
  }

  // Devolver el singular de la jerarquía hija
  // empresa -> zona
  // zona -> localización
  // localización -> sublocalización
  // sublocalización -> extra
  const getSingularOfChild = type => {
    switch (type) {
      case 'empresa':
        return 'zona';

      case 'zona':
        return 'localización';

      case 'localización':
        return 'sublocalización'

      case 'sublocalización':
        return 'extra'

      default:
        return 'extra'
    }
  }

  const handleChangesToPositions = () => {
    setNewChangesToPositions({})
    seTedit('')
  }

  return (
    <>
      {addNodoAlert && (
        <Alert className={classes.alertNav} severity='success'>
          Nodo agregado correctamente
        </Alert>
      )}

      <ListItem
        button
        selected={selectedNode ? selectedNode.id === node.id : false}
        className={clsx(classes.selected, classNodeSelect(node))}
        onClick={() => handleSelectedNode(node)}
        disabled={!node.owner}
      >
        {!node.owner && (
          <Tooltip title={`El nodo ${node.nombre} es compartido`}>
            <IconButton edge='end' aria-label='nodo compartido' size='small' color='primary'>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )}
        <Box bgcolor={node.color} p={0.25} m={0.5} width={20} height={3}></Box>

        <ListItemText primary={node.nombre} secondary={`(${node.level_nombre})`} className={classes.nodeHeader} />

        <ListItemSecondaryAction>

          {/* Icon edicion */}
          {checkDevice(node) && !isMobile &&
            (edit === '' ?
              <Tooltip title={`Cambiar órden de nodos`}>
                <IconButton
                  edge='end'
                  variant="contained"
                  color="primary"
                  className={classes.buttonEditPositions}
                  onClick={() => {
                    setFlagEdit({
                      node_id: node.id,
                      node_name: node.nombre,
                      node_level_name: node.level_nombre
                    })
                    setCollapseChildrens(true)
                  }}
                >
                  <ImportExportIcon />
                </IconButton>
              </Tooltip>
              :
              <Tooltip title={`Guardar configuración de nuevo orden.`}>
                <IconButton
                  className={classes.buttonEditPositions}
                  edge='end'
                  variant="contained"
                  color="primary"
                  onClick={handleChangesToPositions}
                >
                  <SaveAsIcon />
                </IconButton>
              </Tooltip>
            )
          }

          {/* Icon no posee direccion cargada */}
          {!node.has_address && node.level_nombre === 'localización' && !isMobile &&
            <Tooltip title={`${node.nombre} no tiene una dirección cargada`}>
              <IconButton
                edge='end'
                aria-label='alertas asociadas'
                classes={!node.hijos.length ? { root: classes.meterWithoutChild } : {}}
              >
                <WarningIcon />
              </IconButton>
            </Tooltip>
          }

          {/* Icono usuario generador*/}
          {node.es_generador &&
            <Tooltip title={`Usuario generador`}>
              <IconButton
                edge='end'
                aria-label='es generador'
              >
                <WbSunnyIcon style={{ color: '#F59500' }} />
              </IconButton>
            </Tooltip>
          }

          {/* Icono bidireccional */}
          {node.es_bidireccional &&
            <Tooltip title={`Bidireccional`}>
              <IconButton
                edge='end'
                aria-label='bidireccional'
              >
                <SyncAltSharpIcon style={{ color: '#0000008a' }} />
              </IconButton>
            </Tooltip>
          }

          {/* Icon automate */}
          {node?.automates?.length > 0 &&
            <Tooltip title={`${node.nombre} tiene un automate asociado`}>
              <IconButton
                edge='end'
                aria-label='automates asociados'
              >
                <PrecisionManufacturingIcon style={selectedNode.id === node.id ? { color: '#d8d8d8' } : {}} />
              </IconButton>
            </Tooltip>
          }

          {/* Icon medidor fisico */}
          {node.medidor &&
            <Tooltip title={`${node.nombre} tiene un medidor asociado ${nodeStatus?.online ? 'online' : 'offline'}`}>
              <IconButton
                edge='end'
                aria-label='medidores asociados'
              >
                <SpeedIcon style={nodeStatus?.online ? { color: '#66bb6a' } : { color: '#ef5350' }} />
              </IconButton>
            </Tooltip>
          }

          {node.hijos &&
            node.hijos.length > 0 &&
            (open ? (
              <IconButton
                style={selectedNode.id === node.id ? { color: '#d8d8d8' } : {}}
                edge='end' aria-label='contraer' onClick={handleArrows}>
                <ExpandLessIcon />
              </IconButton>
            ) : (
              <IconButton
                style={selectedNode.id === node.id ? { color: '#d8d8d8' } : {}}
                edge='end' aria-label='expandir' onClick={handleArrows}>
                <ExpandMoreIcon />
              </IconButton>
            ))}

          {!node.last_level && plan?.info?.plan !== "Demo" && (
            <Tooltip
              title={getTotalOfChildNodes(node?.level_nombre) + 1 > plan?.menu?.max_locations ? `Ha alcanzado el máximo de ${getPluralOfChild(node.level_nombre)} permitidas para su plan.` : `Presione para crear una ${getSingularOfChild(node.level_nombre)}`} arrow placement='right'>
              <span>
                <IconButton
                  edge='end'
                  aria-label='expandir'
                  onClick={() => {
                    handleAdd(node);
                    alertNodo();
                  }}
                  disabled={getTotalOfChildNodes(node?.level_nombre) + 1 > plan?.menu?.max_locations}
                >
                  <AddIcon style={selectedNode.id === node.id ? { color: '#d8d8d8' } : {}} />
                </IconButton>
              </span>
            </Tooltip>

          )}

        </ListItemSecondaryAction>
      </ListItem>

      {
        node.hijos ? (
          <Collapse in={open} timeout='auto' unmountOnExit className={classes.nested}>
            <List component='div' disablePadding>
              {node.hijos
                ?
                <>
                  {
                    edit !== '' ?
                      <DragDropList
                        setClose={setClose}
                        setFlagEdit={setFlagEdit}
                        flagEdit={flagEdit}
                        array={node.hijos}
                        handleSelectedNode={handleSelectedNode}
                        selectedNode={selectedNode}
                        setTreeChanges={setTreeChanges}
                        setNewChangesToPositions={setNewChangesToPositions}
                        collapse={collapseChildrens}
                      />
                      :
                      <>
                        {node.hijos.map(c => (
                          <Node
                            setClose={setClose}
                            setFlagEdit={setFlagEdit}
                            flagEdit={flagEdit}
                            node={c}
                            key={c.id}
                            handleSelectedNode={handleSelectedNode}
                            selectedNode={selectedNode}
                            setTreeChanges={setTreeChanges}
                            status={status}
                            setNewChangesToPositions={setNewChangesToPositions} />
                        ))}
                      </>
                  }
                </>
                :
                null
              }

            </List>
          </Collapse>
        ) : null
      }

    </>
  );
}
