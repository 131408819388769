import { useEffect, useState } from "react";

const useDeviceDetect = (value) => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const onResize = () => {
      let pixel = 720;
      if (value) {
        pixel = value;
      }
      if (document.body.clientWidth <= pixel) {
        // console.log("Mobile");
        setMobile(true);
      } else {
        // console.log("Desktop");
        setMobile(false);
      }
    };

    // Llamar a la función onResize al inicio para verificar el tamaño de la pantalla
    onResize();

    // Agregar el listener para el resize
    window.addEventListener("resize", onResize, { passive: true });

    // Limpieza del listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [value]); // El useEffect se ejecutará solo si "value" cambia

  return { isMobile };
};

export default useDeviceDetect;