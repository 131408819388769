import { marked } from "marked";
import DOMPurify from "dompurify";
import "katex/dist/katex.min.css";
import katex from "katex";

export const MAVG_OPTIONS = [
    {
        name: '4',
        key: '4'
    },
    {
        name: '8',
        key: '8'
    },
    {
        name: '16',
        key: '16'
    },
    {
        name: '32',
        key: '32'
    },
    {
        name: '64',
        key: '64'
    },
]

export const INA_OPTIONS = [
    {
        name: 'Tension 0 - 10V',
        key: '0'
    },
    {
        name: 'Tension 2 - 10V',
        key: '1'
    },
    {
        name: ' Tension 0 - 5V',
        key: '2'
    },
    {
        name: 'Tension 1 - 5V',
        key: '3'
    },
    {
        name: 'Corriente 0 - 20mA',
        key: '4'
    },
    {
        name: 'Corriente 4 - 20mA',
        key: '5'
    },
]

export const BOOL_OPTIONS = [
    {
        name: 'Verdadero',
        key: true
    },
    {
        name: 'Falso',
        key: false
    },
]


export const BOOLEAN_TEXT_OPTIONS = [
    {
        name: 'Booleano',
        key: '0'
    },
    {
        name: 'Entero',
        key: '1'
    },
    {
        name: 'Entero no signado',
        key: '2'
    },
    {
        name: 'Flotante',
        key: '3'
    },
]
export const CONST_OPTIONS = [
    {
        name: 'bool',
        key: '0'
    },
    {
        name: 'int',
        key: '1'
    },
    {
        name: 'uint',
        key: '2'
    },
    {
        name: 'float',
        key: '3'
    },
]


//MBS
export const MBS_OPTIONS_TR = [
    {
        value: 'Holding Register',
        key: '0'
    },
    {
        value: 'Input Register',
        key: '1'
    },
]

export const MBS_OPTIONS_TD = [
    {
        value: '16 bit Unsigned Int (1 registro)',
        key: '0'
    },
    {
        value: '16 bit Int (1 registro)',
        key: '1'
    },
    {
        value: '32 bit Unsigned Int (2 registros)',
        key: '2'
    },
    {
        value: '32 bit Int (2 registros)',
        key: '3'
    },
    {
        value: '32 bit Float IEE754 (2 registros)',
        key: '4'
    },

]

export const MBS_OPTIONS_ENDIANNES = [
    {
        value: 'Big Endian',
        key: '0'
    },
    {
        value: 'Little Endian',
        key: '1'
    },
    {
        value: 'Big Endian Byte Swap',
        key: '2'
    },
    {
        value: 'Little Endian Byte Swap',
        key: '3'
    },
]

export const MBR_OPTIONS_INTERFAZ = [
    {
        value: 'TCP',
        key: '0'
    },
    {
        value: 'RTU',
        key: '1'
    },
]


export const MarkdownRenderer = ({ markdown }) => {
    // Configurar `marked` para procesar ecuaciones en $$...$$
    marked.setOptions({
        breaks: true, // Para respetar saltos de línea
        highlight: false,
    });
    // Reemplazar ecuaciones en $$...$$ con su renderizado en KaTeX
    const renderMath = (text) => {
        return text.replace(/\$\$(.*?)\$\$/gs, (match, equation) => {
            return katex.renderToString(equation, {
                throwOnError: false,
                displayMode: true, // Para ecuaciones de bloque
            });
        });
    };
    // Convertir Markdown a HTML con `marked`
    let htmlContent = marked(markdown);
    // Procesar ecuaciones matemáticas con `KaTeX`
    htmlContent = renderMath(htmlContent);
    return (
        <div
            style={{
                width: '100%', // Ajuste al 100% del contenedor
                maxWidth: '100%', // Se asegura de que no se desborde
                overflowWrap: 'break-word', // Romper palabras largas
                wordWrap: 'break-word', // Romper palabras largas en texto largo
            }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }}
        />
    );
};