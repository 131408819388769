import { StateDiagramProvider } from "./DiagramContext"
import DynamicDiagram from "../DynamicDiagram"
//Params router 
import { useParams } from 'react-router';

const WrapperDiagram = ({ componentType, setRefresh }) => {
    const { params_id } = useParams();

    return (
        <StateDiagramProvider>
            <DynamicDiagram
                componentTypeAux={componentType}
                params_id={params_id}
                setRefresh={setRefresh}
            />
        </StateDiagramProvider>
    )
}
export default WrapperDiagram