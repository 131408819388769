import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import clsx from 'clsx';
import { blueBar } from '../../helpers/common';
import AddIcon from '@material-ui/icons/Add';
import DatasetFilter from './DatasetFilter';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import DynamicSelect from '../common/DynamicSelect';
import UserContext from '../../context/UserContext';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FILTER_INITIAL_VALUES } from './helpers';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { NavLink } from 'react-router-dom';

let scrollsSettings = {
  overflow: 'scroll',
  overflowX: 'hidden',
};
let webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  color: 'red',
  width: '9px',
};
let webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  label: {
    marginLeft: theme.spacing(3),
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      marginLeft: theme.spacing(1),
      marginBottom: '5px',
    },
  },
  btn: {
    margin: theme.spacing(3, 1),
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  hint: {
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    minWidth: '25px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '200px',
    },
  },
  secondaryContainer: {
    border: `1px dashed ${theme.palette.secondary.light}`,
    borderRadius: '20px',
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    minHeight: '34%',
  },
  primaryContainer: {
    minHeight: '34%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    border: '1px solid #00000029',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  help: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: theme.spacing(0.5)
  },
  labelHowWorks: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}));

export default function Filter({ filterOptions, setFilterPanel, onSubmit }) {
  const classes = useStyles();
  const blueLine = blueBar();
  const [activeStep, setActiveStep] = useState(0);
  const { isMobile } = useDeviceDetect(990);
  const { plan } = useContext(UserContext);
  const steps = [
    'Elija la variable a graficar',
    'Elija la granularidad',
    'Complete el grupo primario',
    'Complete el grupo secundario (Opcional)',
    'Complete el grupo terciario (Opcional)',
  ];
  const [showDatasetFilter, setShowDatasetFilter] = useState(false);
  const [datasetSelected, setDatasetSelected] = useState(null);
  const [indexGroupselected, setIndexGroupselected] = useState(null);
  const [indexDataSetSelected, setIndexDataSetSelected] = useState(null);
  const [mainObj, setMainObj] = useState(filterOptions);
  const enabledItems = plan?.sections['HISTORICOS'].filter(
    item => item.enabled
  );

  const objectCatalog = {};
  const [filterCalendarConfig, setFilterCalendarConfig] = useState({
    views: ['month', 'year'],
    disabled: false,
    format: 'MM/yyyy',
  });
  const [showCleanAllDialog, setShowCleanAllDialog] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [calendars, setCalendars] = useState(FILTER_INITIAL_VALUES.groups.map(() => ({ open: false })));

  const filterConfigCalendar = () => {
    const config = {};
    switch (mainObj.granularity) {
      case 'DAILY':
        config.views = ['month', 'year'];
        config.disabled = false;
        config.format = 'MM/yyyy';
        //setSelectedDate(new Date());
        break;
      case 'MONTHLY':
        config.views = ['year'];
        config.disabled = false;
        config.format = 'yyyy';
        //setSelectedDate(new Date());
        break;
      case 'YEARLY':
        config.disabled = true;
        config.format = 'yy';
        //Como voy a mostrar todos los anuales muestro la fecha de hoy
        //setSelectedDate(new Date());
        break;
      default:
        return;
    }
    setFilterCalendarConfig(config);
  };

  enabledItems.forEach(item => {
    objectCatalog[item.key] = {
      key: item.key,
      name: item.tag ? item.tag : item.name,
      fields: item.fields,
      periods: item.periods,
      phase: item.phase,
    };
  });

  useEffect(() => {
    calculateActiveStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Actualizo el tipo de calendario de acuerdo a la granularidad recibida.
    filterConfigCalendar(mainObj);
    // eslint-disable-next-line 
  }, [mainObj]);

  const handleFilterChange = e => {
    if (e.target.name === 'variable') {
      let granularity = enabledItems?.find(item => item.key === e.target.value);
      let period = granularity.periods[0]?.key;
      //Si elije la otra variable y la granularidad q tenia coincide con una opcion valida la sigo mostrando.
      let findSameGranularity = granularity?.periods.find(
        item => item.key === mainObj?.granularity
      );
      setMainObj({
        ...mainObj,
        variable: e.target.value,
        granularity: findSameGranularity ? findSameGranularity.key : period,
        showPhase: objectCatalog[e.target.value]?.phase,
      });
      if (!mainObj.variable) {
        setActiveStep(2);
      }
    }
    if (e.target.name === 'granularity') {
      setMainObj({
        ...mainObj,
        granularity: e.target.value,
      });
      if (!mainObj.granularity) {
        setActiveStep(2);
      }
      filterConfigCalendar({
        ...mainObj,
        granularity: e.target.value
      });
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(mainObj);
      setFilterPanel(false);
    }
  };

  const handleOpenDatasetModal = (indexGroup, e) => {
    //si hay evento de click
    if (e) {
      let newMainObj = mainObj;
      let newMainObjDatasets = newMainObj.groups[indexGroup].datasets;
      newMainObjDatasets.push({});
      setMainObj(newMainObj);
      setDatasetSelected(newMainObjDatasets[newMainObjDatasets.length - 1]);
      setIndexGroupselected(indexGroup);
      setIndexDataSetSelected(newMainObjDatasets.length - 1);
      setShowDatasetFilter(true);
    }
  };

  const addDataSetButton = (indexLevel, disableCondition) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Box className={classes.devicesLabel}>
          Grupo {getLabelOfIndexLevel(indexLevel)}. Agregar dataset
        </Box>
        <IconButton
          aria-label="agregar dataset"
          className={classes.addBtn}
          onClick={e => handleOpenDatasetModal(indexLevel, e)}
          disabled={disableCondition}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const getLabelOfIndexLevel = indexLevel => {
    switch (indexLevel) {
      case 0:
        return 'Primario';
      case 1:
        return 'Secundario';
      case 2:
        return 'Terciario';
      default:
        return '';
    }
  };

  const handleSecondarySwitch = e => {
    const updatedMainObj = { ...mainObj };
    if (e.target.checked) {
      updatedMainObj.groups[1].disabled = false;
    } else {
      updatedMainObj.groups[1].disabled = true;
      updatedMainObj.groups[1].datasets = [];
      updatedMainObj.groups[2].disabled = true;
      updatedMainObj.groups[2].datasets = [];
    }
    setMainObj(updatedMainObj);
  };

  const handleTertiarySwitch = e => {
    const updatedMainObj = { ...mainObj };
    if (e.target.checked) {
      updatedMainObj.groups[2].disabled = false;
    } else {
      updatedMainObj.groups[2].disabled = true;
      updatedMainObj.groups[2].datasets = [];
    }
    setMainObj(updatedMainObj);
  };

  const handleDeleteDataset = (indexGroup, indexDataset) => {
    const updatedMainObj = { ...mainObj };
    updatedMainObj.groups = [...mainObj.groups];
    updatedMainObj.groups[indexGroup] = {
      ...mainObj.groups[indexGroup],
      datasets: [...mainObj.groups[indexGroup].datasets],
    };
    updatedMainObj.groups[indexGroup].datasets.splice(indexDataset, 1);
    setMainObj(updatedMainObj);
    let datasetsGroup1 = updatedMainObj.groups[0].datasets;
    let datasetsGroup2 = updatedMainObj.groups[1].datasets;
    if (datasetsGroup1.length === 0) {
      setActiveStep(2);
    } else if (datasetsGroup2.length === 0) {
      setActiveStep(3);
    }
  };

  const handleCloseDatasetFilter = () => {
    setShowDatasetFilter(false);
  };

  const thereAreDatasetsInGroup = indexGroup => {
    return mainObj.groups[indexGroup].datasets.some(dataset => dataset.alias)
  }

  const addGroup = () => {
    let indexGroup = 2;
    if (mainObj.groups[2].disabled && !mainObj.groups[1].disabled) {
      indexGroup = 2;
    } else {
      indexGroup = 1
    }
    const updatedMainObj = { ...mainObj };
    updatedMainObj.groups[indexGroup].disabled = false;
    setMainObj(updatedMainObj);
  }

  const toggleCalendar = (index, isOpen) => {
    setCalendars((prev) =>
      prev.map((item, i) => (i === index ? { open: isOpen } : item))
    );
  };

  const calendar = (groupIndex, disabled) => {
    return (
      <Grid item xs={12} md={6}>
        <Box className={classes.filterLabel}>Fecha</Box>

        {mainObj.granularity !== 'YEARLY' ? (
          <Box display="flex" flexDirection={"column"} alignItems="center">
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={filterCalendarConfig.disabled || disabled}
                autoOk
                views={filterCalendarConfig.views}
                disableToolbar
                variant="inline"
                format={filterCalendarConfig.format}
                margin="dense"
                value={mainObj?.groups[groupIndex]?.selectedDate}
                onChange={(date) => setSelectedDateOfGroup(date, groupIndex)}
                KeyboardButtonProps={{
                  "aria-label": "Establecer fecha",
                }}
                InputProps={{
                  disableUnderline: true,
                  inputProps: { style: { textAlign: "center" } }
                }}
                open={calendars[groupIndex]?.open}
                onOpen={() => toggleCalendar(groupIndex, true)}
                onClose={() => toggleCalendar(groupIndex, false)}
                keyboardIcon={null}
              />
            </MuiPickersUtilsProvider>

            {/* Botón para abrir el calendario */}
            <Button  onClick={() => toggleCalendar(groupIndex, true)} variant="contained" color="primary" style={{marginRight: '20px'}}>
              Seleccionar Fecha
              <InsertInvitationIcon style={{ marginLeft: '10px' }} />

            </Button>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <p className={classes.txtAcumuladoHistorico}>Acumulado Histórico</p>
          </Box>
        )}
      </Grid>
    );
  };

  const setSelectedDateOfGroup = (date, indexGroup) => {
    let updatedGroups = mainObj.groups;
    updatedGroups[indexGroup].selectedDate = date;
    setMainObj({
      ...mainObj,
      groups: updatedGroups,
    });
  };

  const handleCopyDataset = (indexGroup) => {
    let updatedGroups = mainObj.groups;
    updatedGroups[indexGroup].datasets = [...mainObj.groups[0].datasets];
    setMainObj({
      ...mainObj,
      groups: updatedGroups
    })
  }

  function calculateActiveStep() {
    if (thereAreDatasetsInGroup(2)) {
      setActiveStep(5);
    } else if (thereAreDatasetsInGroup(1)) {
      setActiveStep(4);
    } else if (thereAreDatasetsInGroup(0)) {
      setActiveStep(3);
    } else if (mainObj.granularity) {
      setActiveStep(2);
    }
  }

  const handleCleanFilter = () => {
    let filter = { ...FILTER_INITIAL_VALUES }
    filter.groups = filter.groups.map(group => ({
      ...group,
      disabled: true,
      datasets: [],
    }));
    setMainObj(filter)
    setActiveStep(0)
    setShowCleanAllDialog(false)
  }

  return (
    <>
      <Box padding={3} className={classes.root}>
        <Stepper
          className={classes.steppersFilter}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{isMobile ? '' : label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {blueLine}

        <p>Bienvenido a históricos avanzados, en esta sección usted podrá construir gráficos de barras versátiles que se adapten al análisis que desee realizar (ejemplo: comparación de consumo de energía entre diferentes períodos, filtrar consumos por franja horaria, comparar energía entre diferentes localizaciones, etc.).
          <b className={classes.labelHowWorks} onClick={() => setShowPreview(true)}> <br /> Click aquí para ver como funciona</b></p>
        <p>NOTA: Si esta herramienta le resulta compleja o difícil de utilizar, le sugerimos primero probar con la sección <NavLink to='/historicos'>Históricos</NavLink>.</p>

        <Grid container>

          {/* Variable */}
          <Grid item xs={12}>
            <Box display="flex" minHeight={40} alignItems="flex-end">
              <Tooltip
                title="Elija la variable a graficar"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: activeStep === 0,
                    },
                    {
                      [classes.tick]: activeStep > 0 || mainObj?.variable,
                    }
                  )}
                >
                  1
                </Box>
              </Tooltip>
              <DynamicSelect
                disabledSelect={false}
                disabledVarianteHistoric={false}
                handleChangeProp={handleFilterChange}
                valueSelect={mainObj.variable}
                keyName={'variable'}
                InputLabelProp={'Variable principal a graficar'}
                section={'HISTORICOS'}
                placeHolder={'Variable'}
              />
            </Box>
            {
              !mainObj.variable &&
              <Box mt={1} className={classes.help}>Debe elegir una variable.</Box>
            }
          </Grid>

          {/* Granularidad */}
          <Grid item xs={12} >
            <Box display="flex" alignItems="center" mt={2}>
              <Tooltip
                title="Elija la granularidad deseada"
                arrow
                placement="right"
              >
                <Box
                  className={clsx(classes.hint, classes.leftSpacing, {
                    [classes.tick]: mainObj?.granularity,
                  })}
                >
                  2
                </Box>
              </Tooltip>
              <FormControl fullWidth>
                <InputLabel id="granularityLabel">Granularidad</InputLabel>
                <Select
                  style={{ width: '90%' }}
                  labelId="granularityLabel"
                  name="granularity"
                  value={mainObj.granularity}
                  onChange={handleFilterChange}
                  disabled={!mainObj.variable}
                >
                  {objectCatalog[mainObj.variable]?.periods.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {
              !mainObj.granularity &&
              <Box mt={1} className={classes.help}>Debe elegir una granularidad.</Box>
            }
          </Grid>


          {/* GRUPO 1 */}
          <Grid item xs={12} className={classes.primaryContainer}>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              minHeight={40}>
              <Tooltip
                title="Agrega un dataset haciendo click en el botón +"
                arrow
                placement="right">
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: activeStep === 2,
                    },
                    {
                      [classes.tick]: activeStep > 2 || thereAreDatasetsInGroup(0),
                    }
                  )}>
                  3
                </Box>
              </Tooltip>
              {addDataSetButton(0)}
            </Box>
            <Box ml={4} mb={1}>
              {mainObj?.groups[0]?.datasets?.map(
                (dataset, indx) =>
                  dataset?.alias && (
                    <Chip
                      className={classes.chip}
                      key={indx}
                      icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                      label={dataset.alias}
                      clickable
                      color="primary"
                      onClick={() => {
                        setDatasetSelected(dataset);
                        setIndexDataSetSelected(indx);
                        setIndexGroupselected(0);
                        setShowDatasetFilter(true);
                      }}
                      onDelete={() => handleDeleteDataset(0, indx)}
                    ></Chip>
                  )
              )}
            </Box>
            <Box display={'flex'} justifyContent={'center'} mt={2} mb={4}>{calendar(0)}</Box>
            {
              !thereAreDatasetsInGroup(0) &&
              <Box mt={1} className={classes.help}>Debe agregar al menos un dataset.</Box>
            }
          </Grid>


          {/* GRUPO 2 */}
          {!mainObj?.groups[1].disabled && (
            <Box
              className={classes.secondaryContainer}
              display="flex"
              flexDirection="column"
            >
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Box display="flex" alignItems="center">
                    <Tooltip
                      title="Elija si desea mostrar un gráfico secundario"
                      arrow
                      placement="right"
                    >
                      <Box
                        className={clsx(
                          classes.hint,
                          classes.leftSpacing,
                          {
                            [classes.active]: activeStep === 3,
                          },
                          {
                            [classes.tick]: thereAreDatasetsInGroup(1),
                          }
                        )}
                      >
                        4
                      </Box>
                    </Tooltip>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            name="showSecondary"
                            onChange={handleSecondarySwitch}
                            color="secondary"
                            checked={!mainObj.groups[1].disabled}
                          />
                        }
                      />
                    </FormGroup>
                    <Box className={classes.filterLabel}>
                      Habilitar grupo secundario
                    </Box>
                    <Tooltip title="Copiar datasets del Grupo Primario">
                      <Button disabled={mainObj.groups[1].disabled} onClick={() => handleCopyDataset(1)}>
                        <ContentCopyIcon style={{ fontSize: '18px' }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </FormControl>
                <Box ml={4}>
                  {addDataSetButton(1, mainObj?.groups[1].disabled)}
                  {mainObj?.groups[1]?.datasets?.map(
                    (dataset, indx) =>
                      dataset.alias && (
                        <Chip
                          className={classes.chip}
                          key={indx}
                          icon={
                            <LabelImportantIcon style={{ fontSize: '18px' }} />
                          }
                          label={dataset.alias}
                          clickable
                          color="primary"
                          onClick={() => {
                            setDatasetSelected(dataset);
                            setIndexDataSetSelected(indx);
                            setIndexGroupselected(1);
                            setShowDatasetFilter(true);
                          }}
                          onDelete={() => handleDeleteDataset(1, indx)}
                        ></Chip>
                      )
                  )}
                </Box>
                <Box display={'flex'} justifyContent={'center'} mt={2} mb={4}>{calendar(1, mainObj.groups[1].disabled)}</Box>
              </Grid>
            </Box>
          )}

          {/* BOTON AGREGAR GRUPO */}
          {(mainObj.groups[1].disabled || mainObj.groups[2].disabled) &&
            <Box display="flex" width={'100%'} px={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={addGroup}
              >
                Agregar Grupo
              </Button>
            </Box>
          }


          {/* GRUPO 3 */}
          {!mainObj.groups[2].disabled && (
            <Box
              className={classes.secondaryContainer}
              display="flex"
              flexDirection="column"
            >
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Box display="flex" alignItems="center">
                    <Tooltip
                      title="Elija si desea mostrar un gráfico secundario"
                      arrow
                      placement="right"
                    >
                      <Box
                        className={clsx(
                          classes.hint,
                          classes.leftSpacing,
                          {
                            [classes.active]: activeStep === 4,
                          },
                          {
                            [classes.tick]: thereAreDatasetsInGroup(2),
                          }
                        )}
                      >
                        5
                      </Box>
                    </Tooltip>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            name="showTertiary"
                            onChange={handleTertiarySwitch}
                            color="secondary"
                            disabled={mainObj?.groups[1].disabled}
                            checked={!mainObj.groups[2].disabled}
                          />
                        }
                      />
                    </FormGroup>
                    <Box className={classes.filterLabel}>
                      Habilitar grupo terciario
                    </Box>
                    <Tooltip title="Copiar datasets del Grupo Primario">
                      <Button disabled={mainObj.groups[2].disabled} onClick={() => handleCopyDataset(2)}>
                        <ContentCopyIcon style={{ fontSize: '18px' }} />
                      </Button>
                    </Tooltip>
                  </Box>
                </FormControl>
                <Box ml={4}>
                  {addDataSetButton(2, mainObj?.groups[2].disabled)}
                  {mainObj?.groups[2]?.datasets?.map((dataset, indx) => (
                    dataset.alias &&
                    <Chip
                      className={classes.chip}
                      key={indx}
                      icon={<LabelImportantIcon style={{ fontSize: '18px' }} />}
                      label={dataset.alias}
                      clickable
                      color="primary"
                      onClick={() => {
                        setDatasetSelected(dataset);
                        setIndexDataSetSelected(indx);
                        setIndexGroupselected(2);
                        setShowDatasetFilter(true);
                      }}
                      onDelete={() => handleDeleteDataset(2, indx)}
                    ></Chip>
                  ))}
                </Box>
                <Box display={'flex'} justifyContent={'center'} mt={2} mb={4}>{calendar(2, mainObj.groups[2].disabled)}</Box>
              </Grid>
            </Box>
          )}

        </Grid>
      </Box>
      <Box position="sticky" bottom="0" bgcolor={"#f7f7f9"} justifyContent={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box width={'96%'}>
          {blueLine}
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
            disabled={
              !mainObj.variable ||
              !mainObj.granularity ||
              !thereAreDatasetsInGroup(0)
            }
          >
            Consultar
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilterPanel(false)}
            className={classes.btn}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => setShowCleanAllDialog(true)}
            variant="contained"
            className={classes.btn}
            color='secondary'
          >
            Limpiar datos
          </Button>
        </Box>
      </Box>

      <Dialog
        open={showDatasetFilter}
        onClose={handleCloseDatasetFilter}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DatasetFilter
          setShowDatasetFilter={setShowDatasetFilter}
          filterOptions={filterOptions}
          datasetSelected={datasetSelected}
          setMainObj={setMainObj}
          mainObj={mainObj}
          indexGroupselected={indexGroupselected}
          indexDataSetSelected={indexDataSetSelected}
          setActiveStepMainFilter={setActiveStep}
        />
      </Dialog>

      <Dialog
        open={showCleanAllDialog}
        onClose={() => setShowCleanAllDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <Box>
          <DialogTitle id="form-dialog-title">{`¿Está seguro que desea limpiar el filtro?`}</DialogTitle>
          <DialogContent>
            En caso de confirmar, se procederá a eliminar todos los cambios realizados en el filtro.
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={handleCleanFilter}>
                Aceptar
              </Button>
              <Button
                variant="contained"
                onClick={() => setShowCleanAllDialog(false)}
                className={classes.btn}>
                Cancelar
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>

      <Dialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Históricos (avanzado) - Instrucciones de uso</DialogTitle>
        {blueLine}
        <DialogContent >
          En esta sección podrá generar un gráfico de barras altamente personalizable. Las variables seleccionadas podrán presentarse en forma de barras apiladas y/o columnas independientes. Cada una de las barras apiladas se denomina dataset: un dataset está conformado por la elección de un nodo, franja horaria, color personalizado (por defecto se utiliza el color propio del nodo seleccionado) y una franja horaria (opcional). Cada conjunto de datasets conforma un grupo (que se visualiza como una columna). Se permite un máximo de hasta 3 grupos de datasets por gráfico.
          <Box display={'flex'} justifyContent={'center'} p={5}>
            <img src="/images/historical_advanced_preview.webp"
              alt="logo" />
          </Box>
          {blueLine}
          <Box display={'flex'} justifyContent={'center'}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={() => setShowPreview(false)}>
              Aceptar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
