import { Box, Button, List, Tooltip, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
// Servicios
import {
  getNodes,
  getNodesTree,
  getTags,
  postNode,
  updateNodesTree,
} from '../../services/hierarchy';
import { getStatusNodes } from '../../services/meters';
// Components
import AlertComponent from '../Elements/AlertComponent';
import Container from '../Elements/Container';
import LabelInfo from './LabelInfo';
import Labels from './Labels';
import Node from './Node';
import NodeInfo from './NodeInfo';
import TopNav from '../common/TopNav';
// Custom Hooks
import useDeviceDetect from '../../hooks/useDeviceDetect';
// Context
import UserContext from '../../context/UserContext';
import NodesListContext from '../../context/NodesListContext';
import NodesContext from '../../context/NodesContext';
// LocalStorage
import {
  saveTagsInLocalStorage,
  saveNodesInLocalStorage,
  saveNodesListInLocalStorage,
} from '../../services/auth';
import TagsContext from '../../context/TagsContext';

const scrollsSettings = {
  overflow: 'scroll',
  height: '90vh',
  overflowX: 'hidden',
};

const webkitScrollbar = {
  background: 'white',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#beb1b18f',
  width: '9px',
};

const webkitscrollbarTrack = {
  background: '#dfdfe957',
};

const useStyles = makeStyles(theme => ({
  root: {
    // margin: theme.spacing(2, 5, 2, 10),
    color: theme.palette.primary.main,
    display: 'flex'
  },
  btnTopNav: {
    backgroundColor: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      opacity: 0.9,
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiButton-label': {
      fontSize: 11,
      textTransform: 'none',
    },
  },
  btnAddCompany: {
    marginBottom: '10px',
    '& .MuiButton-label': {
      fontSize: 11,
      textTransform: 'none',
    },
  },
  leftContainer: {
    width: '50%',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    //son los estilos de las barras scroll en una variable.
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '@media (max-width: 1220px)': {
      width: '100%',
    },
  },
  rightContainer: {
    width: '50%',
    // borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    //son los estilos de las barras scroll en una variable
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      ...webkitScrollbar,
    },
    '&::-webkit-scrollbar-track': {
      ...webkitscrollbarTrack,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '@media (max-width: 1220px)': {
      width: '100%',
    },
  },
  buttonBack: {
    marginTop: '15px',
    position: 'relative',
    bottom: '16px',
    fontSize: '20px',
    backgroundColor: '#f3f3f3',
    width: '30px',
    borderRadius: '1em',
    textAlign: 'center',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
}));

export default function Devices({ match }) {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect(1200);
  const { plan } = useContext(UserContext);
  const { nodes, setNodes } = useContext(NodesContext);
  const { tags, setTags } = useContext(TagsContext);
  const { nodesList, setNodesList } = useContext(NodesListContext);
  const [status, setStatus] = useState([])
  const [selectedNode, setSelectedNode] = useState({});
  const [selectedLabel, setSelectedLabel] = useState('');
  const [treeChanges, setTreeChanges] = useState(false);
  const [treeLabelsChanges, setTreeLabelsChanges] = useState(false);

  const [showRightBlock, setShowRightBlock] = useState(true);
  const [showLeftBlock, setShowLeftBlock] = useState(true);
  const [loading, setLoading] = useState(true);
  //Estados alert
  const [close, setClose] = useState(false);
  const [msg, setMsg] = useState({
    msg: '',
    type: 'success',
  });
  const [flagEdit, setFlagEdit] = useState({})
  const [newChangesToPositions, setNewChangesToPositions] = useState(null)

  useEffect(() => {
    const fetchNodesTreeData = async () => {
      try {
        const res = await getNodesTree();
        saveNodesInLocalStorage(res.data);
        setNodes(res.data);
        const resStatus = await getStatusNodes();
        setStatus(resStatus.data);
      }
      catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    fetchNodesTreeData();
  }, [treeChanges, setNodes]);

  useEffect(() => {
    if (isMobile) {
      setShowRightBlock(false);
    }
    else {
      setShowLeftBlock(true);
      setShowRightBlock(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (newChangesToPositions !== null) {
      let nodeToModify = findNodeToEditChildrenPositions(nodes, flagEdit?.node_id);
      const ordenMap = {};
      flagEdit.position_children?.forEach((num, index) => {
        ordenMap[num] = index;
      });
      nodeToModify.hijos = reorganizeChildrens(nodeToModify.hijos, ordenMap)
      setSelectedNode({
        ...selectedNode,
        hijos: nodeToModify.hijos
      })
      saveNewOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChangesToPositions]);

  useEffect(() => {
    const fetchLabelsData = async () => {
      const res = await getTags();
      setTags(res.data);
      saveTagsInLocalStorage(res.data);
    };

    fetchLabelsData();
  }, [treeLabelsChanges, setTags]);

  // Obtengo todos los nodos representados como un array.
  useEffect(() => {
    const fetchNodes = async () => {
      try {
        const res = await getNodes();
        saveNodesListInLocalStorage(res.data);
        setNodesList(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchNodes();
  }, [treeChanges, setNodesList]);

  const [showNodes, setShowNodes] = useState(true);

  useEffect(() => {
    //Si recibo dispositivos/1 muestro Nodos sino muestro etiqeutas
    if (match.path === '/configuracion-organizacion') {
      setShowNodes(true);
    } else {
      setShowNodes(false);
    }
  }, [match.path]);

  const handleSelectedNode = node => {
    setSelectedNode(node);
    if (isMobile) {
      setShowLeftBlock(false);
      setShowRightBlock(true);
    }
  };

  const saveNewOrder = async () => {
    try {
      await updateNodesTree(nodes)
    } catch (error) {
      setClose(true);
      setMsg({
        msg: `${`Ocurrió un error inesperado`}.`,
        type: 'error',
      });
      setTreeChanges(current => !current);
    }
  }

  const handleSelectedLabel = (label, id, color) => {
    setSelectedLabel({ nombre: label, id: id, color: color });
    if (isMobile) {
      setShowLeftBlock(false);
      setShowRightBlock(true);
    }
  };

  const handleAddCompany = async () => {
    try {
      // Obtengo el id del elemento raíz que será el parent de la nueva empresa.
      const parent = nodesList[0].id;
      const res = await postNode({
        parent: parent,
        nombre: `Nueva Empresa`,
        descripcion: '',
        medidor_virtual: true,
        medidor: null,
      });
      //Selecciono la empresa creada en este momento
      handleSelectedNode(res.data);
      setClose(true);
      setMsg({
        msg: 'Se creo una nueva empresa',
        type: 'success',
      });
      setTreeChanges(current => !current);
    }
    catch (error) {
      if (error.response) {
        setClose(true);
        setMsg({
          msg: `${error.response.data.msg}.`,
          type: 'error',
        });
      }
      else {
        setClose(true);
        setMsg({
          msg: `${`Ocurrió un error inesperado`}.`,
          type: 'error',
        });
      }
    }
  };

  const alertNodo = () => {
    //alert
    setClose(true);
    setMsg({
      msg: 'El nodo se elimino correctamente',
      type: 'error',
    });
  };

  const handleBackClick = () => {
    setShowLeftBlock(true);
    setShowRightBlock(false);
    setFlagEdit('')
  };

  const getTotalOfNodes = useCallback(type => {
    return nodesList.filter(node => node?.level_nombre === type).length;
  }, [nodesList]);

  const findNodeToEditChildrenPositions = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.hijos && node.hijos.length > 0) {
        let childrenNode = findNodeToEditChildrenPositions(node.hijos, id);
        if (childrenNode) {
          return childrenNode;
        }
      }
    }
    return null; // Devuelve null si no se encuentra ningún objeto con el ID especificado
  }

  const reorganizeChildrens = (array, positions) => {
    const reorganizedChildrens = [];
    Object.keys(positions).forEach(key => {
      const index = parseInt(key);
      const newPosition = positions[key];

      reorganizedChildrens[newPosition] = array[index];
    });
    return reorganizedChildrens;
  }

  return (
    <>
      <TopNav titulo={showNodes ? 'Dispositivos' : 'Etiquetas'}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="default"
            className={classes.btnTopNav}
            onClick={() => {
              setShowNodes(true);
              setSelectedLabel(false);
            }}
          >
            Dispositivos
          </Button>
          <Button
            color="default"
            className={classes.btnTopNav}
            onClick={() => {
              setShowNodes(false);
              setSelectedNode({});
            }}
          >
            Etiquetas
          </Button>
        </Box>
      </TopNav>

      {/* ALERTAS */}
      <AlertComponent
        severity={msg.type}
        msg={msg.msg}
        setClose={setClose}
        close={close}
      />

      <Container>
        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Box className={classes.root} >
            {showLeftBlock && (
              <Box className={classes.leftContainer}>
                <List>
                  {showNodes && (
                    <Box>
                      {plan?.info?.plan !== 'Demo' && (
                        <Tooltip title={getTotalOfNodes('empresa') + 1 > plan?.menu?.max_locations ? 'Ha alcanzado el máximo de empresas permitidas para su plan.' : 'Presione para crear una empresa'} arrow placement='right'>
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.btnAddCompany}
                              onClick={handleAddCompany}
                              // Deshabilito el botón si en caso de crear (por eso el +1) superaría la cantidad admitida.
                              disabled={getTotalOfNodes('empresa') + 1 > plan?.menu?.max_locations}
                            >
                              Nueva Empresa
                            </Button>
                          </span>

                        </Tooltip>
                      )}
                      {nodes.map(node => (
                        <Node
                          node={node}
                          handleSelectedNode={handleSelectedNode}
                          selectedNode={selectedNode}
                          key={node.id}
                          setTreeChanges={setTreeChanges}
                          status={status}
                          setFlagEdit={setFlagEdit}
                          flagEdit={flagEdit}
                          setClose={setClose}
                          setNewChangesToPositions={setNewChangesToPositions}
                        />
                      ))}
                    </Box>
                  )}
                  {!showNodes && (
                    <Labels
                      handleSelectedLabel={handleSelectedLabel}
                      selectedLabel={selectedLabel}
                      treeLabelsChanges={treeLabelsChanges}
                      setTreeLabelsChanges={setTreeLabelsChanges}
                      fullTagList={tags}
                    />
                  )}
                </List>
              </Box>
            )}
            {showRightBlock && (
              <Box item xs={12} md={6} className={classes.rightContainer}>
                {isMobile && (
                  <Box className={classes.buttonBack} onClick={handleBackClick}>
                    {'<'}
                  </Box>
                )}
                {showNodes && selectedNode.level_nombre && (
                  <NodeInfo
                    setMsg={setMsg}
                    setClose={setClose}
                    alertNodo={alertNodo}
                    selectedNode={selectedNode}
                    setSelectedNode={(selectedNode) => {
                      setSelectedNode(selectedNode);
                      isMobile && handleBackClick();
                    }}
                    setTreeChanges={setTreeChanges}
                    nodes={nodesList}
                  />
                )}
                {!showNodes && selectedLabel && (
                  <LabelInfo
                    plan={plan}
                    selectedLabel={selectedLabel}
                    setSelectedLabel={setSelectedLabel}
                    treeLabelsChanges={treeLabelsChanges}
                    setTreeLabelsChanges={setTreeLabelsChanges}
                  />
                )}
              </Box>
            )}
          </Box>
        )}
      </Container>
    </>
  );
}
